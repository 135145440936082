import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import HomePopoutLabel from './HomePopoutLabel.jsx'
import PhotoGallery from './PhotoGallery.jsx'
import BedroomsCount from './BedroomsCount.jsx'
import BathroomsCount from './BathroomsCount.jsx'
import SquareFeetCount from './SquareFeetCount.jsx'
import YearBuilt from './YearBuilt.jsx'
import HomeAcres from './HomeAcres.jsx'
import LocationType from './LocationType.jsx'
import Amenities from './Amenities.jsx'
import HomeownerNote from './HomeownerNote.jsx'
import SignupButton from '../SignupButton.jsx'

const makePropertySelector = () => createSelector(
  (state) => state.properties,
  (_, id) => id,
  (properties, id) => find(properties, { id }),
)

const HomeDetails = ({ id }) => {
  const selectProperty = useMemo(makePropertySelector, [])
  const property = useSelector((state) => selectProperty(state, id))

  if (property) {
    return (
      <>
        <div className="popout-card-header-wrapper">
          <div>
            <h1>{property.address}</h1>
            <p>{`${property.city}, ${property.state} ${property.zip}`}</p>
            <HomePopoutLabel propertyId={property.id} />
          </div>
          {!property.status ? (
            <SignupButton claimButton property={property} />
          ) : null}
        </div>
        <PhotoGallery propertyId={property.id} />
        <LocationType locationType={property.location_type} />
        <div id="home-popout-stats">
          <BedroomsCount count={property.bedrooms} />
          <BathroomsCount count={property.bathrooms} />
          <SquareFeetCount count={property.square_feet} />
          <YearBuilt year={property.year_built} />
          <HomeAcres acres={property.acres} />
        </div>

        <Amenities values={property.amenities} />
        <HomeownerNote note={property.description} />
      </>
    )
  }

  return (
    <p>Details not available..</p>
  )
}

HomeDetails.propTypes = {
  id: PropTypes.number.isRequired,
}

export default HomeDetails
