/* eslint-disable radix */
// For whatever reason, mapbox reverse geocoding does not
// return a unique id.
const generateUniqueId = (id, address) => {
  if (address) {
    return parseInt(`${address.replace(/\s/g, '')}${id}`.replace('address.', ''))
  }

  return null
}

export default generateUniqueId
