import React from 'react'
import PropTypes from 'prop-types'
import SignupImage from '../../../images/signup-image.jpg'
import MobileSignupImage from '../../../images/signup-image-mobile.jpg'

const SlideTwo = ({ handleChangeIndex }) => {
  return (
    <div className="signup-popup-slide-area">
      <div id="signup-popup-preview">
        <img src={window.innerWidth >= 475 ? SignupImage : MobileSignupImage} />
      </div>
      <h1>Claiming your home is Easy!</h1>
      <p><strong>Step 1:</strong> On the next screen, type in your Home Address.</p>
      <p><strong>Step 2:</strong> Click the Green Button (shown above) to Claim Your Home.</p>
      <p><strong>Step 3:</strong> Finalize Your Account by entering your basic information.</p>
      <p>Now you are in the driver’s seat!  You can decide if you want house hunters in your area to send you private, no obligation, Off Market offers on your home without ever having to go through the hassle of listing it “For Sale”!</p>
      <div
        className="btn btn-block btn-primary mt-6"
        onClick={() => handleChangeIndex(2)}
      >
        Next
      </div>
    </div>
  )
}

SlideTwo.propTypes = {
  handleChangeIndex: PropTypes.func.isRequired,
}

export default SlideTwo
