/* eslint-disable no-case-declarations */
const INITIAL_STATE = null

const currentUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_USER_SESSION':
      return action.user

    case 'ADD_WAVE_ID':
      return {
        ...state,
        waves: [
          ...state.waves,
          {
            source_id: action.id,
          },
        ],
      }

    case 'ADD_CLAIMED_HOME':
      return {
        ...state,
        claimed: [
          ...state.claimed,
          { id: action.id, lat: action.lat, lng: action.lng },
        ],
      }

    default: return state
  }
}

export default currentUserReducer
