const clickSubmit = () => {
  const button = document.getElementById('listing-images-submit')
  if (button) {
    document.getElementById('listing-images-submit').click()
  }
}

export const setupLocationImages = () => {
  document.addEventListener('change', clickSubmit)
}

export const teardownLocationImages = () => {
  document.removeEventListener('change', clickSubmit)
}
