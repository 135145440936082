/* eslint-disable no-undef */
import MicroModal from 'micromodal'
import { setupInputMasks, teardownInputMasks } from './inputMasks'

window.showModal = null

export const setupModal = () => {
  window.showModal = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const modalContainer = document.querySelector('#modal-container')
    const url = e.target.getAttribute('href')

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response.text()
      })
      .then((html) => {
        modalContainer.innerHTML = html
        MicroModal.show('modal-container', {
          awaitCloseAnimation: true,
          onShow: () => {
            const recaptcha = document.querySelector('.g-recaptcha ')

            if (recaptcha) {
              grecaptcha.render(recaptcha)
            }

            setupInputMasks()
          },
        })
      })
  }
}

export const cleanupModal = () => {
  teardownInputMasks()
  window.showModal = null
}
