import React from 'react'
import Logo from '../../../images/logo.png'

const SlideThree = () => {
  return (
    <div className="signup-popup-slide-area">
      <div id="signup-popup-logo-wrapper">
        <img src={Logo} />
      </div>
      <h1>Search for your home and get started!</h1>
    </div>
  )
}

export default SlideThree