/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-undef */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Rails dependencies
// ----------------------------------------------
import '@fontsource/rubik/300.css'
import '@fontsource/rubik/400.css'
import '@fontsource/rubik/500.css'
import '@fontsource/rubik/700.css'
import '../css/application.scss'

// React Imports
import { setupApp, destroyApp } from '../src/setup/mounting.jsx'
import { componentMappings } from '../src/setup/components'

// General non React JS imports
import '../src/password-validator'
import { navInit, navBackdropHide, navCleanup } from '../src/nav'
import { hidePageTransition, showPageTransition } from '../src/transitions'
import { uploadFile, uploadCleanup } from '../src/upload'
import { initToggleHidden, cleanupToggleHidden } from '../src/toggle-hidden'
import { attachUserSession } from '../src/sessions'
import { hideHighlightedHome } from '../src/cleanup'
import { alertsCleanup, alertsInit } from '../src/alerts'
import { setupDirectUpload, teardownDirectUpload } from '../src/directUpload'
import { setupLocationImages, teardownLocationImages } from '../src/locationImages'
import { adminSortInit, adminSortCleanup } from '../src/admin-sorting'
import { cleanupModal, setupModal } from '../src/modal'
import { setupInputMasks, teardownInputMasks } from '../src/inputMasks'

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
// Enable for ActionCable
// require('channels')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../images', true)
const imagePath = (name) => { images(name, true) }

const onTurboLinksLoad = () => {
  // Mount React Components
  componentMappings.forEach(({ component, wrapper }) => {
    setupApp(component, wrapper)
  })

  attachUserSession()
  navInit()
  alertsInit()
  uploadFile()
  setupDirectUpload()
  setupLocationImages()
  adminSortInit()
  initToggleHidden()
  setupModal()
  setupInputMasks()

  // Analytics tracking
  try {
    // Facebook Pixel
    fbq('init', '318735652981279')
    fbq('track', 'PageView')
    // Google Analytics
    gtag('js', new Date())
    gtag('config', 'G-TEHMSRHR46')
  } catch (err) {
    // console.log(err)
  }
}

const onBeforeCache = () => {
  navBackdropHide()
  navCleanup()
  alertsCleanup()
  showPageTransition()
  uploadCleanup()
  teardownDirectUpload()
  teardownLocationImages()
  adminSortCleanup()
  cleanupToggleHidden()
  cleanupModal()
  teardownInputMasks()

  // Unmount React Components
  componentMappings.forEach(({ wrapper }) => {
    destroyApp(wrapper)
  })
}

const onTurbolinksRender = () => {
  navBackdropHide()
  hidePageTransition()
  hideHighlightedHome()
}

// Turbolinks hooks:
// Used for mounting and unmounting components and
// Javascript behaviour that has event listeners.
// -------------------------------------------------------
window.addEventListener('turbolinks:load', onTurboLinksLoad)
window.addEventListener('turbolinks:before-cache', onBeforeCache)
window.addEventListener('turbolinks:render', onTurbolinksRender)
