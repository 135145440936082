import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import FilterList from './filters/FilterList.jsx'
import FilterToggle from './filters/FilterToggle.jsx'

const selectFilters = createSelector(
  (state) => state.filters,
  (filters) => filters,
)

const selectOpen = createSelector(
  selectFilters,
  (filters) => filters.open,
)

const Filters = () => {
  const open = useSelector(selectOpen)

  return (
    <div id="filter-options-parent-wrapper" className={open ? 'open' : ''}>
      <FilterToggle />
      <FilterList />
    </div>
  )
}

export default Filters
