import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import Checkbox from '../forms/Checkbox.jsx'
import amenityOptions from '../../amenityOptions'

const selectFilters = createSelector(
  (state) => state.filters,
  (filters) => filters,
)

const selectAmenities = createSelector(
  selectFilters,
  (filters) => filters.amenities,
)

const AmenitiesFilter = () => {
  const amenities = useSelector(selectAmenities)
  const dispatch = useDispatch()

  const handleFilter = (e) => {
    dispatch({
      type: 'UPDATE_FILTER_OPTIONS',
      amenities: e.target.value,
      checked: e.currentTarget.checked,
    })
  }

  return (
    <div className="checkbox-filters">
      {amenityOptions.map((value) => (
        <Checkbox
          key={value}
          name={value}
          value={value}
          checkedState={find(amenities, { option: value })}
          updateAction={handleFilter}
        />
      ))}
    </div>
  )
}

export default AmenitiesFilter
