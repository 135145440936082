/* eslint-disable camelcase */
import IMask from 'imask'

const masks = []

const setupInputMask = (element, maskOptions) => {
  if (element) {
    masks.push(IMask(element, maskOptions))
  }
}

export const setupInputMasks = () => {
  const dollarInputs = [
    document.getElementById('offer_amount'),
    document.getElementById('listing_min_offer'),
  ]

  dollarInputs.forEach((element) => {
    setupInputMask(element, {
      mask: '$num',
      blocks: {
        num: {
          mask: Number,
          thousandsSeparator: ',',
        },
      },
    })
  })

  const listingYearInput = document.getElementById('listing_location_attributes_location_detail_attributes_year_built')
  setupInputMask(listingYearInput, {
    mask: '0000',
  })
}

export const teardownInputMasks = () => {
  masks.forEach((mask) => {
    mask.destroy()
  })
}
