const onClicked = () => {
  document.getElementById('mobile-sort-dropdown-options')
    .classList
    .toggle('open')
}

export const adminSortInit = () => {
  const sortInstance = document.getElementById('mobile-sort-trigger')

  if (sortInstance) {
    sortInstance.addEventListener('click', onClicked)
  }
}

export const adminSortCleanup = () => {
  const sortInstance = document.getElementById('mobile-sort-trigger')

  if (sortInstance) {
    sortInstance.removeEventListener('click', onClicked)
  }
}
