import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import useListFilter from '../../hooks/useListFilter'

const selectFilters = createSelector(
  (state) => state.filters,
  (filters) => filters,
)

const selectStatus = createSelector(
  selectFilters,
  (filters) => filters.status,
)

const FilterSubmit = () => {
  const status = useSelector(selectStatus)
  const dispatch = useDispatch()
  const [updateList] = useListFilter()

  const cleanupMap = () => {
    dispatch({ type: 'CLOSE_FILTER_OPTIONS' })
    dispatch({ type: 'CLOSE_HOME_HIGHLIGHT' })
    const sideBar = document.getElementById('map-sidebar-inner')
    sideBar.scrollTop = 0
  }

  const parseList = (response) => {
    dispatch({ type: 'CLEAR_PROPERTIES' })
    dispatch({ type: 'ADD_PROPERTIES', properties: response.data })
  }
  const parseMap = (response) => {
    dispatch({ type: 'REMOVE_ALL_MARKERS' })
    if (find(status, { option: 'unclaimed', checked: false })) {
      dispatch({ type: 'ADD_OMG_MARKERS_TO_MARKERS', markers: response.data })
    }
  }

  const updateLists = () => {
    updateList({ callbackFunction: parseList })
    updateList({ view: 'map', callbackFunction: parseMap })
    cleanupMap()
  }

  return (
    <button
      id="submit-filters-button"
      className="btn btn-secondary mt-4"
      onClick={updateLists}
    >
      Update List
    </button>
  )
}

export default FilterSubmit
