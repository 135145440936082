import React, { useEffect } from 'react'
import SearchInput from './SearchInput'

const MapSearchWrapper = ({ map, mapboxgl }) => {
  // Could not use just CSS here because of fixed inside fixed elements.
  // Relying on JS to resize search bar to fix on desktop.
  // In mobile it's not an issue because fixed styling is removed.
  const changeFixedElementWidth = () => {
    const parentElement = document.getElementById('map-content-area')
    const fixedElement = document.getElementById('map-search-wrapper')

    if (parentElement && fixedElement) {
      let parentElementWidth
      if (window.innerWidth >= 768) {
        parentElementWidth = `${parentElement.getBoundingClientRect().width}px`
      } else {
        parentElementWidth = '100%'
      }
      fixedElement.style.width = parentElementWidth
    }
  }

  useEffect(() => {
    changeFixedElementWidth()
    window.addEventListener('resize', changeFixedElementWidth)

    return () => {
      window.removeEventListener('resize', changeFixedElementWidth)
    }
  }, [])

  return (
    <div id='map-search-wrapper'>
      <SearchInput mapboxgl={mapboxgl} map={map} />
    </div>
  )
}

export default MapSearchWrapper
