import React from 'react'
import SideBar from './components/SideBar.jsx'
import Map from './components/Map.jsx'
import HomePopout from './components/homes/HomePopout.jsx'
import SiteCredits from './components/SiteCredits.jsx'

const MapEntry = () => (
  <div id="map-layout">
    <div id="map-sidebar">
      <SideBar />
    </div>
    <div id="map-content-area">
      <HomePopout />
      <Map />
      <SiteCredits />
    </div>
  </div>
)

export default MapEntry
