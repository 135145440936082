import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import Checkbox from '../forms/Checkbox.jsx'

const valuePairs = [
  ['Active', 'active'],
  ['Inactive', 'inactive'],
  ['Unclaimed', 'unclaimed'],
  ['Sale Pending', 'pending_sale'],
]

const selectFilters = createSelector(
  (state) => state.filters,
  (filters) => filters,
)

const selectStatus = createSelector(
  selectFilters,
  (filters) => filters.status,
)

const StatusFilter = () => {
  const status = useSelector(selectStatus)
  const dispatch = useDispatch()

  const handleFilter = (e) => {
    dispatch({
      type: 'UPDATE_FILTER_OPTIONS',
      status: e.target.value,
      checked: e.currentTarget.checked,
    })
  }

  return (
    <div className="checkbox-filters">
      {valuePairs.map((pairs) => (
        <Checkbox
          key={pairs[1]}
          name={pairs[0]}
          value={pairs[1]}
          checkedState={find(status, { option: pairs[1] })}
          updateAction={handleFilter}
        />
      ))}
    </div>
  )
}

export default StatusFilter
