import React from 'react'
import PropTypes from 'prop-types'

const HomeownerNotes = ({ note }) => {
  if (note) {
    return (
      <>
        <h5 className="mt-6 mb-4 secondary-heading">
          Note From Homeowner
        </h5>

        <div className="bg-neutral-lighter-gray px-3 py-4 rounded-lg">
          {note}
        </div>
      </>
    )
  }

  return null
}

HomeownerNotes.propTypes = {
  note: PropTypes.string,
}

HomeownerNotes.defaultProps = {
  note: null,
}

export default HomeownerNotes
