export const showPageTransition = () => {
  const wrapperNode = document.getElementById('page-transition-wrapper')
  if (wrapperNode) {
    wrapperNode.classList
      .remove('hidden')
  }
}

export const hidePageTransition = () => {
  const wrapperNode = document.getElementById('page-transition-wrapper')
  if (wrapperNode) {
    wrapperNode.classList
      .add('hidden')
  }
}
