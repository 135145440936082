import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import CloseImage from '../../../images/close.svg'
import HomeDetails from './HomeDetails.jsx'
import ClaimHomeButton from '../ClaimHomeButton.jsx'
import WaveButton from '../WaveButton.jsx'
import MakeOfferButton from '../MakeOfferButton.jsx'
import SignupButton from '../SignupButton.jsx'

const selectSelectedAddress = createSelector(
  (state) => state.homeHighlight,
  (homeHighlight) => homeHighlight.selectedAddress,
)

const selectOpen = createSelector(
  (state) => state.homeHighlight,
  (homeHighlight) => homeHighlight.open,
)

const selectProperties = createSelector(
  (state) => state.properties,
  (properties) => properties,
)

const selectProperty = createSelector(
  [selectSelectedAddress, selectProperties],
  (selectedAddress, properties) => find(properties, { address: selectedAddress }),
)

const HomePopout = () => {
  const open = useSelector(selectOpen)
  const property = useSelector(selectProperty)
  const dispatch = useDispatch()

  useEffect(() => {
    if (open && !property) {
      dispatch({
        type: 'CLOSE_HOME_HIGHLIGHT',
      })
    }
  }, [open, property])

  const closeHighlight = () => {
    dispatch({ type: 'CLOSE_HOME_HIGHLIGHT' })
  }

  const renderPopout = () => {
    if (open && property) {
      return (
        <div id="home-popout" className="animate__animated animate__slideInLeft">
          <div id="home-popout-scroll-area">
            <div
              id="home-popout-close"
              onClick={closeHighlight}>
              <img src={CloseImage} />
            </div>
            <HomeDetails id={property.id} />
          </div>
          <div id="home-popout-button-options">
            <WaveButton id={property.id} />
            <ClaimHomeButton id={property.id} />
            <MakeOfferButton id={property.id} />
            <SignupButton />
          </div>
        </div>
      )
    }

    if (open) {
      return (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )
    }

    return null
  }

  return (
    <div id="home-popout-wrapper" className={ open ? 'w-full' : ''}>
      <div
        id="home-popout-inner-wrapper"
        className={ open ? 'w-full animate__animated animate__fadeIn' : '' }
      >
        {renderPopout()}
      </div>
    </div>
  )
}

export default HomePopout
