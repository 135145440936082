/* eslint-disable radix */
import remove from 'lodash/remove'

const INITIAL_STATE = []

const markersReducer = (state = INITIAL_STATE, action) => {
  const newState = Array.from(state)
  const markerLimit = 1000

  switch (action.type) {
    case 'ADD_MARKERS_TO_MARKERS':

      action.markers.forEach((marker) => {
        remove(newState, (mark) => (
          (mark.source_id === String(marker.id) && marker.id != null)
          || mark.id === marker.id
          || mark.address === marker.address
        ))

        newState.push({
          ...marker,
          source_id: String(marker.id),
        })
      })

      return (
        newState.length > markerLimit
          ? newState.slice(newState.length - markerLimit, markerLimit + 1) : newState
      )

    case 'ADD_OMG_MARKERS_TO_MARKERS':
      for(var i=0;i<action.markers.length;i++) {
        var marker = action.markers[i];
        remove(newState, (mark) => (
          (mark.source_id === marker.source_id && marker.source_id != null)
          || mark.id === marker.id
          || mark.address === marker.address
          || (
            mark.geometry.coordinates[0] === marker.lng
            && mark.geometry.coordinates[1] === marker.lat
          )
        ))

        newState.push({
          ...marker,
          geometry: {
            coordinates: [
              marker.lng,
              marker.lat,
            ],
          },
        })
      }

      return (
        newState.length > markerLimit
          ? newState.slice(newState.length - markerLimit, markerLimit + 1) : newState
      )

    case 'REMOVE_MARKER_FROM_MARKERS':
      remove(newState, (marker) => marker.id === action.id)

      return newState

    case 'REMOVE_ALL_MARKERS':
      return INITIAL_STATE

    default: return state
  }
}

export default markersReducer
