import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import ImageGallery from 'react-image-gallery'
import MainImage from './MainImage.jsx'

const makePropertySelector = () => createSelector(
  (state) => state.properties,
  (_, propertyId) => propertyId,
  (properties, propertyId) => find(properties, { id: propertyId }),
)

const PhotoGallery = ({ propertyId }) => {
  const selectProperty = useMemo(makePropertySelector, [])
  const property = useSelector((state) => selectProperty(state, propertyId))
  const [images, setImages] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (property.image_urls) {
      setImages(
        property.image_urls.map(
          (options) => (
            {
              original: options.medium,
              thumbnail: options.thumbnail,
              fullscreen: options.large,
            }
          ),
        ),
      )
    }
  }, [property.image_urls])

  if (property && (!property.image_urls || property.image_urls.length <= 0)) {
    return (
      <div id="gallery-image-placeholder">
        <MainImage propertyId={property.id} full={true} />
      </div>
    )
  }

  const showGallery = () => {
    dispatch({ type: 'SHOW_HOMEHIGHLIGHT_GALLERY' })
  }

  if (property && images) {
    return (
      <div id="popout-gallery-wrapper">
        <i
          aria-hidden="true"
          id="view-closer-icon"
          className="fa fa-arrows-alt"
          onClick={showGallery}
        />
        <ImageGallery
          items={images}
          showPlayButton={false}
          showFullscreenButton={false}
          showNav={false}
        />
      </div>
    )
  }

  return null
}

PhotoGallery.propTypes = {
  propertyId: PropTypes.number.isRequired,
}

export default PhotoGallery
