import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import CloseImage from '../images/close.svg'

const selectModal = createSelector(
  (state) => state.modal,
  (modal) => modal,
)

const selectModalHtml = createSelector(
  selectModal,
  (modal) => modal.htmlBody,
)

const selectModalLoading = createSelector(
  selectModal,
  (modal) => modal.loading,
)

const selectModalOpen = createSelector(
  selectModal,
  (modal) => modal.open,
)

const selectModalHeading = createSelector(
  selectModal,
  (modal) => modal.heading,
)

const selectModalButtonClass = createSelector(
  selectModal,
  (modal) => modal.buttonClass,
)

const selectModalButtonFunction = createSelector(
  selectModal,
  (modal) => modal.buttonFunction,
)

const selectModalButtonText = createSelector(
  selectModal,
  (modal) => modal.buttonText,
)

const ModalEntry = () => {
  const htmlBody = useSelector(selectModalHtml)
  const loading = useSelector(selectModalLoading)
  const open = useSelector(selectModalOpen)
  const heading = useSelector(selectModalHeading)
  const buttonClass = useSelector(selectModalButtonClass)
  const buttonFunction = useSelector(selectModalButtonFunction)
  const buttonText = useSelector(selectModalButtonText)
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch({ type: 'CLOSE_MODAL' })
  }

  const renderBodyContent = () => {
    if (loading) {
      return (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )
    }

    return (
      <div dangerouslySetInnerHTML={{ __html: htmlBody }} />
    )
  }

  if (open) {
    return (
      <div id="react-modal-wrapper">
        <div className="react-modal animate__animated animate__fadeInDown">
          <div className="flex mb-4">
            <h2 className="flex-1 text-large font-medium mr-3">{heading}</h2>
            <div
              className="flex-none react-modal-close"
              onClick={closeModal}
            >
              <img src={CloseImage} />
            </div>
          </div>
          {renderBodyContent()}
          <button
            className={`${buttonClass} mt-4`}
            onClick={buttonFunction}
            disabled={loading ? true : ''}
          >
            {buttonText}
          </button>
        </div>
      </div>
    )
  }

  return null
}

export default ModalEntry
