import React from 'react'
import PropTypes from 'prop-types'
import amenityOptions from '../../amenityOptions'

const Amenities = ({ values }) => {
  const renderOptions = () => (
    amenityOptions.map((amenity) => (
      <span key={amenity} className="checkbox">
        <input className="check_boxes optional" type="checkbox" checked={!!(values && values.includes(amenity))} readOnly />
        <label className="collection_check_boxes home-output-accessory-option">
          {amenity}
        </label>
      </span>
    ))
  )

  return (
    <>
      <h5 className="mt-6 mb-4 secondary-heading">
        Amenities
      </h5>

      <div id="amenities-output">
        <div className="check-box-columns">
          <div className="input check_boxes optional">
            {renderOptions()}
          </div>
        </div>
      </div>
    </>
  )
}

Amenities.propTypes = {
  values: PropTypes.array,
}

Amenities.defaultProps = {
  values: [],
}

export default Amenities
