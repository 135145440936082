import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CloseImage from '../images/close.svg'
import SwipeableViews from 'react-swipeable-views'
import SlideOne from './components/signupModal/SlideOne'
import SlideTwo from './components/signupModal/SlideTwo'
import SlideThree from './components/signupModal/SlideThree'
import SearchInput from './components/map/SearchInput'

const SignupModalEntry = ({ mapboxgl, map }) => {
  const slideLength = 3
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(0)
  const [adjustHeight, setAdjustHeight] = useState(false)

  useEffect(() => {
    if (gon.is_guest) {
      setOpen(true)
    }


    setAdjustHeight(true)
  }, [])

  const afterSearch = () => {
    setOpen(false)
    setTimeout(() => {
      dispatch({ type: 'OPEN_HOME_HIGHLIGHT' })
    }, 3000)
  }

  const handleChangeIndex = (i) => {
    if (i < 0) {
      setIndex(slideLength - 1)
    }
    if (i > slideLength - 1) {
      setIndex(0)
    }
    if (i >= 0 && i < slideLength) {
      setIndex(i)
    }
  }

  const renderNavDots = () => {
    return [...Array(slideLength)].map((v, i) => {
      let className = null
      if (i === index) {
        className = "fa fa-circle"
      } else {
        className = "fa fa-circle-thin"
      }

      return (
        <i
          key={'dot-' + i}
          className={className}
          aria-hidden="true"
          onClick={() => handleChangeIndex(i)}
        />
      )
    })
  }

  if (open) {
    return (
      <div id="react-modal-wrapper">
        <div className="react-modal signup-modal animate__animated animate__fadeInDown">
          <div className="flex mb-4 relative">
            <div
              id="signup-popup-close-button"
              onClick={() => setOpen(false)}
            >
              <img src={CloseImage} />
            </div>
          </div>
          <div id="signup-modal-body">
            <SwipeableViews
              index={index}
              onChangeIndex={handleChangeIndex}
              ignoreNativeScroll
              animateHeight={adjustHeight}
            >
              <div id="onboarding-slide-1-wrapper">
                <SlideOne handleChangeIndex={handleChangeIndex} />
              </div>
              <div id="onboarding-slide-2-wrapper" className="pb-6">
                <SlideTwo handleChangeIndex={handleChangeIndex} />
              </div>
              <div id="onboarding-slide-3-wrapper">
                <SlideThree />
              </div>
            </SwipeableViews>

            {index === 2 ? (
              <div className="relative z-50">
                <SearchInput
                  mapboxgl={mapboxgl}
                  map={map}
                  callbackFunction={afterSearch}
                />
              </div>
            ) : null}
          </div>

          <div id="signup-modal-navigation-wrapper">
            <div id="signup-modal-navigation">
              <div onClick={() => handleChangeIndex(index - 1)}>
                <i className="fa fa-angle-left" aria-hidden="true" />
              </div>
              {renderNavDots()}
              <div onClick={() => handleChangeIndex(index + 1)}>
                <i className="fa fa-angle-right" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default SignupModalEntry
