import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'

const selectFilters = createSelector(
  (state) => state.filters,
  (filters) => filters,
)

const selectBathCount = createSelector(
  selectFilters,
  (filters) => filters.bathCount,
)

const BathsFilter = () => {
  const bathCount = useSelector(selectBathCount)
  const dispatch = useDispatch()

  const handleFilter = (e) => {
    dispatch({
      type: 'UPDATE_FILTER_OPTIONS',
      bathCount: parseInt(e.target.value, 10),
    })
  }

  return (
    <div className="baths-filter-wrapper">
      <label>Bath</label>
      <select onChange={handleFilter} value={bathCount || ''}>
        <option value="1">1+</option>
        <option value="2">2+</option>
        <option value="3">3+</option>
        <option value="4">4+</option>
        <option value="5">5+</option>
      </select>
    </div>
  )
}

export default BathsFilter
