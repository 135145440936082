import axios from 'axios'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

const selectBounds = createSelector(
  (state) => state.mapBounds,
  (mapBounds) => mapBounds.bounds,
)

const selectFilters = createSelector(
  (state) => state.filters,
  (filters) => filters,
)

const selectStatus = createSelector(
  selectFilters,
  (filters) => filters.status,
)

const selectHomeTypes = createSelector(
  selectFilters,
  (filters) => filters.homeTypes,
)

const selectAmenities = createSelector(
  selectFilters,
  (filters) => filters.amenities,
)

const selectBedCount = createSelector(
  selectFilters,
  (filters) => filters.bedCount,
)

const selectBathCount = createSelector(
  selectFilters,
  (filters) => filters.bathCount,
)

// Used to call the Rails backend locations endpoint.
function useListFilter() {
  const bounds = useSelector(selectBounds)
  const filters = useSelector(selectFilters)
  const status = useSelector(selectStatus)
  const homeTypes = useSelector(selectHomeTypes)
  const amenities = useSelector(selectAmenities)
  const bedCount = useSelector(selectBedCount)
  const bathCount = useSelector(selectBathCount)
  let cancelCall = false

  function updateList({
    callbackFunction,
    view,
  }) {
    let builtQuery = '/locations.json?'
    builtQuery += `sw=${bounds.sw.lat},${bounds.sw.lng}`
    builtQuery += `&ne=${bounds.ne.lat},${bounds.ne.lng}`
    builtQuery += `&view=${view || 'list'}`

    status.forEach((stat) => {
      if (stat.checked === true) {
        let statusType

        if (stat.option === 'active') {
          statusType = 'accepting_offers'
        } else if (stat.option === 'inactive') {
          statusType = 'claimed'
        } else if (stat.option === 'pending_sale') {
          statusType = 'pending_sale'
        } else if (!view || view === 'list') {
          cancelCall = true
        }

        if (statusType) {
          builtQuery += `&status[]=${statusType}`
        }
      }
    })

    homeTypes.forEach((homeType) => {
      if (homeType.option && homeType.checked === true) {
        builtQuery += `&type[]=${homeType.option}`
      }
    })

    amenities.forEach((amenity) => {
      if (amenity.option && amenity.checked === true) {
        builtQuery += `&amenities[]=${amenity.option}`
      }
    })

    if (bedCount) {
      builtQuery += `&bedrooms=${filters.bedCount}`
    }

    if (bathCount) {
      builtQuery += `&bathrooms=${filters.bathCount}`
    }

    if (cancelCall === false) {
      axios.get(
        builtQuery,
      )
        .then((response) => {
          if (response.data) {
            callbackFunction(response)
          }
        })
        .catch(() => {})
    } else {
      callbackFunction({ data: [] })
    }
  }

  return [updateList]
}

export default useListFilter
