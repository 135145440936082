import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import WaveImage from '../../images/wave.svg'
import waveAtHome from '../hooks/waveAtHome'

const selectCurrentUser = createSelector(
  (state) => state.currentUser,
  (currentUser) => currentUser,
)

const selectClaimed = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.claimed,
)

const selectWaves = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.waves,
)

const WaveButton = ({ id }) => {
  const currentUser = useSelector(selectCurrentUser)
  const claimed = useSelector(selectClaimed)
  const waves = useSelector(selectWaves)
  const property = useSelector((state) => find(state.properties, { id }))
  const dispatch = useDispatch()
  const [wave] = waveAtHome(property)

  const openWaveConfirmation = () => {
    dispatch({
      type: 'OPEN_MODAL',
      heading: 'Wave to the Homeowner?',
      htmlBody: "<p>Waving is OMG’s way to let a homeowner know that people are showing an interest in their home.</p><p class='mt-2'>Rest assured, waving does not commit you to any obligations, such as making an offer. Sometimes homeowners just need a little extra encouragement be open to offers on their home by Going Green!</p>",
      buttonText: 'I love your home, Please Go Green!',
      buttonClass: 'btn btn-block btn-primary',
      buttonFunction: wave,
    })
  }

  if (
    !currentUser
    || (claimed && find(claimed, { id }))
  ) {
    return null
  }

  if (
    waves
    && find(waves, { source_id: property.source_id })
  ) {
    return (
      <div className="btn btn-gray-dotted-outline bg-neutral-white inline-block" style={{ margin: '0.5rem' }}>
        You Waved at This Home!
      </div>
    )
  }

  if (
    currentUser.id
    && property
    && (
      !property.status
      || property.status === 'unclaimed'
      || property.status === 'claimed'
    )
  ) {
    return (
      <div className="button-option-wrapper">
        <button
          className="btn btn-primary btn-block"
          onClick={openWaveConfirmation}
        >
          <img className="inline-block mr-2" src={WaveImage} />
          Wave to Show Interest
        </button>
      </div>
    )
  }

  return null
}

WaveButton.propTypes = {
  id: PropTypes.number.isRequired,
}

export default WaveButton
