import React, { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'

const selectHomeHighlight = createSelector(
  (state) => state.homeHighlight,
  (homeHighlight) => homeHighlight,
)

const selectSelectedAddress = createSelector(
  selectHomeHighlight,
  (homeHighlight) => homeHighlight.selectedAddress,
)

const selectGalleryOpen = createSelector(
  selectHomeHighlight,
  (homeHighlight) => homeHighlight.galleryOpen,
)

const selectProperties = createSelector(
  (state) => state.properties,
  (properties) => properties,
)

const FullScreenGallery = () => {
  const selectedAddress = useSelector(selectSelectedAddress)
  const galleryOpen = useSelector(selectGalleryOpen)
  const properties = useSelector(selectProperties)
  const [property, setProperty] = useState()
  const [images, setImages] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (galleryOpen === true) {
      setProperty(find(properties, { address: selectedAddress }))
    }
  }, [galleryOpen])

  useEffect(() => {
    if (property) {
      setImages(property.image_urls.map(
        (options) => (
          {
            original: options.large,
            thumbnail: options.thumbnail,
            fullscreen: options.large,
          }
        ),
      ))
    }
  }, [property])

  const hideGallery = () => {
    dispatch({ type: 'HIDE_HOMEHIGHLIGHT_GALLERY' })
  }

  if (galleryOpen && images) {
    return (
      <div id="full-screen-gallery-wrapper">
        <i
          onClick={hideGallery}
          className="fa fa-times"
          aria-hidden="true"
        />
        <div id="full-screen-gallery-inner-wrapper">
          <ImageGallery items={images} showFullscreenButton={false} />
        </div>
      </div>
    )
  }

  return true
}

export default FullScreenGallery
