let timer = null

const closeNotice = () => {
  document.getElementsByClassName('notice')[0].classList
    .toggle('hidden')
}

export const alertsInit = () => {
  const alertToggle = document.getElementsByClassName('close-notice')

  if (alertToggle && alertToggle[0]) {
    timer = setTimeout(closeNotice, 3500)

    alertToggle[0].onclick = () => {
      clearTimeout(timer)
      closeNotice()
    }
  }
}

export const alertsCleanup = () => {
  const alertToggle = document.getElementsByClassName('close-notice')

  if (alertToggle) {
    alertToggle.onclick = null
  }

  if (timer) {
    clearTimeout(timer)
  }
}
