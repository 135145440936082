import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { mapStyle, satStyle } from '../../utils/mapStyle'

const StyleToggle = ({ map }) => {
  const [styleUrl, setStyleUrl] = useState(mapStyle)
  const [showMessage, setShowMessage] = useState(false)

  const showTooltip = () => {
    setShowMessage(true)
  }

  const hideTooltip = () => {
    setShowMessage(false)
  }

  const toggleMapStyle = () => {
    if (styleUrl === satStyle) {
      map.current.setStyle(mapStyle)
      setStyleUrl(mapStyle)
    } else {
      map.current.setStyle(satStyle)
      setStyleUrl(satStyle)
    }
  }

  const renderToggleMessage = () => {
    if (showMessage) {
      return (
        <span className="animate__animated animate__pulse">
          Toggle Map Style
        </span>
      )
    }

    return null
  }

  return (
    <div
      id="map-style-toggle"
      onClick={toggleMapStyle}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {renderToggleMessage()}
      {styleUrl === mapStyle ? (
        <i className="fa fa-globe text-neutral-gray" aria-hidden="true" />
      ) : (
        <i className="fa fa-globe text-primary-green" aria-hidden="true" />
      )}
    </div>
  )
}

StyleToggle.propTypes = {
  map: PropTypes.object.isRequired,
}

export default StyleToggle
