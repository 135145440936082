import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import LazyLoad from 'react-lazyload'
import UnclaimedImage from '../../../images/home-icon-gray.svg'

const makePropertySelector = () => createSelector(
  (state) => state.properties,
  (_, propertyId) => propertyId,
  (properties, propertyId) => find(properties, { id: propertyId }),
)

const key = document.querySelector('meta[name="google-street-view"]').content

const MainImage = ({ propertyId, full }) => {
  const selectProperty = useMemo(makePropertySelector, [])
  const property = useSelector((state) => selectProperty(state, propertyId))
  const [streetView, setStreetView] = useState()

  const hasPhotos = () => (
    property.image_urls && property.image_urls.length > 0
  )

  const sizing = () => {
    if (full) {
      return '800x375'
    }

    if (window.innerWidth < 768) {
      return '120x94'
    }

    return '800x375'
  }

  useEffect(() => {
    if (key && property && !hasPhotos()) {
      setStreetView(`https://maps.googleapis.com/maps/api/streetview?size=${sizing()}&location=${property.address} ${property.city} ${property.state} ${property.zip}&key=${key}`)
    }
  }, [propertyId])

  if (!property) {
    return null
  }

  if (!hasPhotos()) {
    if (streetView) {
      return (
        <LazyLoad
          once
          scrollContainer={'#map-sidebar-inner'}
        >
          <img src={streetView} />
        </LazyLoad>
      )
    }

    return (
      <div className="unclaimed-home-image">
        <img src={UnclaimedImage} />
      </div>
    )
  }

  return (
    <img src={property.image_urls[0].thumbnail} />
  )
}

MainImage.propTypes = {
  full: PropTypes.bool,
  propertyId: PropTypes.number.isRequired,
}

MainImage.defaultProps = {
  full: false,
}

export default MainImage
