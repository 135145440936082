/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import GreenHomeImage from '../../images/green-home.svg'
import GrayHomeImage from '../../images/gray-home.svg'
import ClaimDisclaimer from './ClaimDisclaimer.jsx'
import BlackHomeImage from '../../images/black-home.svg'

const ListingStatusToggle = () => {
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (gon.listing_status !== '') {
      setStatus(gon.listing_status)
    }
  }, [gon.listing_status])

  const classValue = (type) => {
    if (status === type && type === 'claimed') {
      return 'btn-black-outline'
    }

    if (status === type) {
      return 'btn-secondary'
    }

    return 'btn-gray-dotted-outline'
  }

  const setAccepting = () => {
    setStatus('accepting_offers')
  }

  const setClaimed = () => {
    setStatus('claimed')
  }

  const onInputChange = () => null

  const renderHomeIcon = (type) => {
    if (status === type && type === 'claimed') {
      return (
        <img src={BlackHomeImage} />
      )
    }

    if (status === type) {
      return (
        <img src={GreenHomeImage} />
      )
    }

    return <img src={GrayHomeImage} />
  }

  return (
    <>
      <div className="form-col-row mb-3">
        <div
          onClick={setAccepting}
          className={`btn btn-block cursor-pointer ${classValue('accepting_offers')}`}
        >
          {renderHomeIcon('accepting_offers')}
          Yes, send me offers on my home!
        </div>

        <div
          onClick={setClaimed}
          className={`btn btn-block cursor-pointer ${classValue('claimed')}`}
        >
          {renderHomeIcon('claimed')}
          No, I don&apos;t want offers.
        </div>
      </div>
      <input
        className="string hidden"
        type="text"
        value={status}
        onChange={onInputChange}
        name="listing[status]"
        id="listing_status"
        hidden
      />

      <ClaimDisclaimer status={status} />
    </>
  )
}

export default ListingStatusToggle
