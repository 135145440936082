import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import HomeLabelImage from '../../../images/home-icon-white.svg'

const makePropertySelector = () => createSelector(
  (state) => state.properties,
  (_, propertyId) => propertyId,
  (properties, propertyId) => find(properties, { id: propertyId }),
)

const CardStatusLabel = ({ propertyId }) => {
  const selectProperty = useMemo(makePropertySelector, [])
  const property = useSelector((state) => selectProperty(state, propertyId))

  const renderImage = () => (
    <img src={HomeLabelImage} className="home-label-image" />
  )

  const labelText = () => {
    if (!property.status) {
      return (
        <span>Inactive</span>
      )
    }

    if (property.status === 'pending_sale') {
      return (
        <span>Sale Pending</span>
      )
    }

    if (property.status === 'claimed') {
      return (
        <span>Not Accepting Offers</span>
      )
    }

    return (
      <span>Active</span>
    )
  }

  const labelClass = () => {
    if (!property.status) {
      return 'inactive'
    }

    if (property.status === 'pending_sale') {
      return 'contract'
    }

    if (property.status === 'claimed') {
      return 'claimed'
    }

    return ''
  }

  return (
    <div className={`home-card-label ${labelClass()}`}>
      {renderImage()}
      {labelText()}
    </div>
  )
}

CardStatusLabel.propTypes = {
  propertyId: PropTypes.number.isRequired,
}

export default CardStatusLabel
