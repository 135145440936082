import React from 'react'
import PropTypes from 'prop-types'

const HomeAcres = ({ acres, rounded }) => (
  <div
    className={`home-card-details-item${rounded ? ' rounded' : ''}`}
  >
    <p>Acreage</p>
    <h4>{acres || '---'}</h4>
  </div>
)

HomeAcres.propTypes = {
  acres: PropTypes.string,
  rounded: PropTypes.bool,
}

HomeAcres.defaultProps = {
  rounded: false,
  acres: null,
}

export default HomeAcres
