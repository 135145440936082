/* eslint-disable camelcase */
import { useDispatch } from 'react-redux'
import generateUniqueId from '../utils/uniqueId'

function selectSearchedResult() {
  const dispatch = useDispatch()

  const selectSearched = ({ event, geoCoder }) => {
    const { address, geometry, place_name } = event.result
    const id = generateUniqueId(event.result.id, address)
    const addressParts = place_name.split(', ')
    const lng = geometry.coordinates[0]
    const lat = geometry.coordinates[1]

    if (id) {
      dispatch({
        type: 'SET_CARD_LOADING',
        loading: true,
      })

      dispatch({
        type: 'ADD_PROPERTIES',
        properties: [{
          id,
          address: addressParts[0],
          source_id: id,
          city: addressParts[1],
          state: addressParts[2].split(' ')[0],
          zip: addressParts[2].split(' ')[1],
          lat,
          lng,
        }],
      })
      dispatch({
        type: 'ADD_MARKERS_TO_MARKERS',
        markers: [{
          id,
          source_id: id,
          address: addressParts[0],
          geometry: {
            coordinates: [
              lng,
              lat,
            ],
          },
        }],
      })
      setTimeout(() => {
        dispatch({
          type: 'SELECT_HIGHLIGHTED_MARKER',
          selectedMarkerId: addressParts[0],
        })
        dispatch({
          type: 'SELECT_HIGHLIGHTED_HOME',
          selectedAddress: addressParts[0],
        })
      }, 1500)
    }

    setTimeout(() => {
      if (geoCoder.current) {
        geoCoder.current.clear()
      }
    }, 1000)
  }

  return [selectSearched]
}

export default selectSearchedResult
