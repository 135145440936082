import axios from 'axios'

// Used to query the Mapbox geocoding endpoint.
function useReverseGeocode() {
  const reverseGeocode = ({
    lng,
    lat,
    accessToken,
    callbackFunction,
  }) => {
    axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json`,
      {
        params: {
          types: 'address',
          access_token: accessToken,
        },
      },
    )
      .then((response) => {
        callbackFunction(response)
      })
      .catch(() => {
      })
  }

  return [reverseGeocode]
}

export default useReverseGeocode
