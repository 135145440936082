/* eslint-disable no-case-declarations */
import unionBy from 'lodash/unionBy'

const INITIAL_STATE = {
  open: false,
  homeTypes: [
    { option: 'house', checked: false },
    { option: 'condo', checked: false },
    { option: 'townhome', checked: false },
    { option: 'multifamily', checked: false },
    { option: 'manufactured', checked: false },
    { option: 'land', checked: false },
    { option: 'other', checked: false },
    { option: 'apartment', checked: false },
  ],
  bedCount: 0,
  bathCount: 0,
  status: [
    { option: 'active', checked: false },
    { option: 'inactive', checked: false },
    { option: 'unclaimed', checked: false },
    { option: 'pending_sale', checked: false },
  ],
  amenities: [
    { option: 'A/C', checked: false },
    { option: 'Pool/Spa', checked: false },
    { option: 'Waterfront', checked: false },
    { option: 'City Views', checked: false },
    { option: 'Park Views', checked: false },
    { option: 'Mountain Views', checked: false },
    { option: 'Water Views', checked: false },
    { option: 'Single Story', checked: false },
    { option: 'Has Basement', checked: false },
    { option: 'Has Garage', checked: false },
  ],
  acres: null,
  squareFeet: null,
  years: null,
}

const filtersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'TOGGLE_FILTER_OPTIONS':
      return {
        ...state,
        open: !state.open,
      }

    case 'CLOSE_FILTER_OPTIONS':
      return {
        ...state,
        open: false,
      }

    case 'CLEAR_HOME_TYPE_OPTIONS':
      return {
        ...state,
        homeTypes: INITIAL_STATE.homeTypes,
      }

    case 'UPDATE_FILTER_OPTIONS':
      let statusValue
      let homeTypeValues
      let amenitiesValues

      if (action.status) {
        if (
          (action.status === 'unclaimed')
          && (action.checked === true)
        ) {
          statusValue = [
            { option: 'active', checked: false },
            { option: 'inactive', checked: false },
            { option: 'unclaimed', checked: true },
            { option: 'pending_sale', checked: false },
          ]
        } else {
          statusValue = unionBy(
            [{ option: action.status, checked: action.checked }],
            state.status,
            'option',
          )
        }
      }

      if (action.homeType) {
        homeTypeValues = unionBy(
          [{ option: action.homeType, checked: action.checked }],
          state.homeTypes,
          'option',
        )
      }

      if (action.amenities) {
        amenitiesValues = unionBy(
          [{ option: action.amenities, checked: action.checked }],
          state.amenities,
          'option',
        )
      }

      return {
        ...state,
        homeTypes: homeTypeValues || state.homeTypes,
        bedCount: action.bedCount || state.bedCount,
        bathCount: action.bathCount || state.bathCount,
        status: statusValue || state.status,
        amenities: amenitiesValues || state.amenities,
        acres: action.acres || state.acres,
        squareFeet: action.squareFeet || state.squareFeet,
        years: action.years || state.years,
      }

    default: return state
  }
}

export default filtersReducer
