import React from 'react'
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'

const LocationType = ({ locationType }) => {
  if (locationType && locationType !== 'unknown' && locationType !== '') {
    return (
      <h5 className="mt-4 secondary-heading">{startCase(locationType)}</h5>
    )
  }

  return null
}

LocationType.propTypes = {
  locationType: PropTypes.string,
}

LocationType.defaultProps = {
  locationType: null,
}

export default LocationType
