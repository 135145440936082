/* eslint-disable radix */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import CloseImage from '../../../images/close.svg'
import BedroomsCount from '../homes/BedroomsCount.jsx'
import BathroomsCount from '../homes/BathroomsCount.jsx'
import SquareFeetCount from '../homes/SquareFeetCount.jsx'
import CardStatusLabel from '../homes/CardStatusLabel.jsx'
import MainImage from '../homes/MainImage.jsx'

const makePropertySelector = () => createSelector(
  (state) => state.properties,
  (_, propertyId) => propertyId,
  (properties, propertyId) => find(properties, { id: propertyId }),
)

const selectHighlightId = createSelector(
  (state) => state.homeHighlight,
  (homeHighlight) => homeHighlight.selectedAddress,
)

const selectCardLoading = createSelector(
  (state) => state.homeHighlight,
  (homeHighlight) => homeHighlight.cardLoading,
)

const makeMarkerSelector = () => createSelector(
  (state) => state.markers,
  (_, property) => property,
  (markers, property) => find(markers, (o) => (
    o.id === parseInt(property.source_id) || o.id === property.id
  )),
)

const Home = ({ propertyId }) => {
  const selectProperty = useMemo(makePropertySelector, [])
  const selectMarker = useMemo(makeMarkerSelector, [])
  const property = useSelector((state) => selectProperty(state, propertyId))
  const selectedAddress = useSelector(selectHighlightId)
  const cardLoading = useSelector(selectCardLoading)
  const marker = useSelector((state) => selectMarker(state, property))
  const dispatch = useDispatch()

  const ifSelected = () => (
    (property && selectedAddress)
    && (selectedAddress === property.address)
  )

  const openPopout = () => {
    if (ifSelected() && cardLoading) {
      return false
    }

    if (marker) {
      const { id, geometry } = marker
      dispatch({
        type: 'UPDATE_MAP_CENTER',
        center: {
          lat: geometry.coordinates[1],
          lng: geometry.coordinates[0],
        },
      })
      dispatch({
        type: 'SELECT_HIGHLIGHTED_MARKER',
        selectedMarkerId: property.address,
      })
    }
    dispatch({
      type: 'SELECT_HIGHLIGHTED_HOME',
      selectedAddress: property.address,
    })
    dispatch({ type: 'OPEN_HOME_HIGHLIGHT' })

    return true
  }

  const closeCard = (e) => {
    e.stopPropagation()
    dispatch({ type: 'CLEAR_PROPERTIES' })
    dispatch({ type: 'CLOSE_HOME_HIGHLIGHT' })
    dispatch({ type: 'SELECT_HIGHLIGHTED_HOME' })
  }

  const renderCardImage = () => (
    <div className="home-card-image">
      <CardStatusLabel propertyId={propertyId} />
      <MainImage propertyId={propertyId} />
    </div>
  )

  const renderCardDetails = () => {
    if (ifSelected() && cardLoading) {
      return (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )
    }

    return (
      <>
        <div className="home-card-details-wrapper">
          <div
            className='home-card-close-option'
            onClick={closeCard}
          >
            <img src={CloseImage} />
          </div>
          <div className="home-card-body">
            {renderCardImage()}
            <div>
              <h2>{property.address}</h2>
              <h3>
                {`${property.city}, ${property.state} ${property.zip}`}
              </h3>
            </div>
          </div>
          <div className="home-card-details">
            <BedroomsCount count={property.bedrooms} rounded={true} />
            <BathroomsCount count={property.bathrooms} rounded={true} />
            <SquareFeetCount count={property.square_feet} rounded={true} />
          </div>
        </div>
      </>
    )
  }

  if (property) {
    return (
      <div
        id={`home-card-${property.address}`}
        className={
          `home-card ${ifSelected() ? 'selected' : ''}`
        }
        onClick={openPopout}
      >
        {renderCardDetails()}
      </div>
    )
  }

  return (
    null
  )
}

Home.propTypes = {
  propertyId: PropTypes.number.isRequired,
}

export default Home
