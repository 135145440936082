/* eslint-disable import/prefer-default-export */
import ModalEntry from '../ModalEntry.jsx'
import MapEntry from '../MapEntry.jsx'
import ListingStatusToggle from '../components/ListingStatusToggle.jsx'
import FullScreenGallery from '../components/FullScreenGallery.jsx'
import SiteCredits from '../components/SiteCredits.jsx'

// Any top level React components to be rendered
// directly into Rails view wrapper divs.
export const componentMappings = [
  {
    component: MapEntry,
    wrapper: 'react-map-entry-component',
  },
  {
    component: ModalEntry,
    wrapper: 'react-modal-entry-component',
  },
  {
    component: ListingStatusToggle,
    wrapper: 'listing-toggle-react-entry',
  },
  {
    component: FullScreenGallery,
    wrapper: 'react-full-screen-gallery',
  },
  {
    component: SiteCredits,
    wrapper: 'react-site-credits',
  },
]
