import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import FilterOptions from './FilterOptions.jsx'
import HomeTypeFilter from './HomeTypeFilter.jsx'
import FilterSubmit from './FilterSubmit.jsx'
import BedsFilter from './BedsFilter.jsx'
import BathsFilter from './BathsFilter.jsx'
import StatusFilter from './StatusFilter.jsx'
import AmenitiesFilter from './AmenitiesFilter.jsx'

const selectFilters = createSelector(
  (state) => state.filters,
  (filters) => filters,
)

const selectOpen = createSelector(
  selectFilters,
  (filters) => filters.open,
)

const FilterList = () => {
  const open = useSelector(selectOpen)

  if (open) {
    return (
      <div id="filter-list-wrapper">
        <div id="filter-list" className="animate__animated animate__slideInDown">
          <FilterOptions title="Home Type">
            <HomeTypeFilter />
          </FilterOptions>

          <div id="bed_bath_filter_row">
            <BedsFilter />
            <BathsFilter />
          </div>

          <div className="mt-3">
            <FilterOptions title="Status">
              <StatusFilter />
            </FilterOptions>
          </div>

          <div className="mt-3">
            <FilterOptions title="Amenities">
              <AmenitiesFilter />
            </FilterOptions>
          </div>

          <FilterSubmit />
        </div>
      </div>
    )
  }

  return null
}

export default FilterList
