const showPosition = (position) => ({
  latitude: position.coords.latitude,
  longitude: position.coords.longitude,
})

const useUserLocation = () => {
  const getUserLocation = async () => {
    if (navigator.geolocation) {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          resolve,
          reject,
          {
            enableHighAccuracy: false,
            timeout: 5000,
            maximumAge: 0,
          },
        )
      })

      return showPosition(position)
    }

    return null
  }

  return [getUserLocation]
}

export default useUserLocation
