/* eslint-disable import/extensions */
/* eslint-disable radix */
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-underscore-dangle */
import React, {
  useLayoutEffect, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import mapboxgl from '!mapbox-gl/dist/mapbox-gl'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import Markers from './map/Markers.jsx'
import StyleToggle from './map/StyleToggle.jsx'
import useReverseGeocode from '../hooks/useReverseGeocode'
import generateUniqueId from '../utils/uniqueId'
import { mapStyle } from '../utils/mapStyle'
import UserLocation from './map/UserLocation.jsx'
import useUserLocation from '../hooks/useUserLocation.js'
import SignupModalEntry from '../SignupModalEntry.jsx'
import MapSearchWrapper from './map/MapSearchWrapper.jsx'

const selectMapboundsCenter = createSelector(
  (state) => state.mapBounds,
  (mapBounds) => mapBounds.center,
)

const selectProperties = createSelector(
  (state) => state.properties,
  (properties) => properties,
)

const selectCurrentUser = createSelector(
  (state) => state.currentUser,
  (currentUser) => currentUser,
)

const selectCurrentUserClaimed = createSelector(
  selectCurrentUser,
  (currentUser) => (
    currentUser && currentUser.claimed ? currentUser.claimed[0] : null
  ),
)

function Map() {
  const mapContainer = useRef(null)
  const map = useRef(null)
  const [renderComponents, setRenderComponents] = useState(false)
  const dispatch = useDispatch()
  const center = useSelector(selectMapboundsCenter)
  const properties = useSelector(selectProperties)
  const claimedHome = useSelector(selectCurrentUserClaimed)
  const propertiesRef = useRef(properties)
  const [reverseGeocode] = useReverseGeocode()
  const [getUserLocation] = useUserLocation()

  useLayoutEffect(() => {
    propertiesRef.current = properties
  }, [properties])

  const getAndSetBounds = () => {
    const mapBounds = map.current.getBounds()

    dispatch({
      type: 'UPDATE_MAP_BOUNDS',
      bounds: {
        ne: {
          lng: mapBounds._ne.lng,
          lat: mapBounds._ne.lat,
        },
        sw: {
          lng: mapBounds._sw.lng,
          lat: mapBounds._sw.lat,
        },
      },
    })
  }

  const onGeocodeSuccess = (e) => {
    const addressValues = e.data.features[0]
    const id = generateUniqueId(addressValues.id, addressValues.address)
    const addressParts = addressValues.place_name.split(', ')
    const lng = addressValues.geometry.coordinates[0]
    const lat = addressValues.geometry.coordinates[1]
    const existingProperty = find(propertiesRef.current, { address: addressParts[0] })

    if (id) {
      if (existingProperty) {
        dispatch({
          type: 'SELECT_HIGHLIGHTED_MARKER',
          selectedMarkerId: existingProperty.address,
        })
        map.current.flyTo({
          center: [
            existingProperty.lng,
            existingProperty.lat,
          ],
        })
        dispatch({
          type: 'SELECT_HIGHLIGHTED_HOME',
          selectedAddress: existingProperty.address,
        })
      } else {
        const property = {
          id,
          source_id: id,
          address: addressParts[0],
          city: addressParts[1],
          state: addressParts[2].split(' ')[0],
          zip: addressParts[2].split(' ')[1],
          lat,
          lng,
        }

        dispatch({
          type: 'ADD_MARKERS_TO_MARKERS',
          markers: [{
            id,
            source_id: id,
            address: addressParts[0],
            geometry: {
              coordinates: [
                lng,
                lat,
              ],
            },
          }],
        })
        dispatch({
          type: 'SELECT_HIGHLIGHTED_MARKER',
          selectedMarkerId: addressParts[0],
        })
        map.current.flyTo({
          center: [
            lng,
            lat,
          ],
        })
        dispatch({
          type: 'ADD_PROPERTIES',
          properties: [property],
        })
        dispatch({
          type: 'SELECT_HIGHLIGHTED_HOME',
          selectedAddress: addressParts[0],
        })
      }
    }
  }

  const loaded = () => {
    map.current.resize()
  }

  const onClicked = (clickEvent) => {
    reverseGeocode({
      lat: clickEvent.lngLat.lat,
      lng: clickEvent.lngLat.lng,
      accessToken: mapboxgl.accessToken,
      callbackFunction: onGeocodeSuccess,
    })
  }

  useEffect(() => {
    // Centers map on claimed home if present on intial load.
    if (claimedHome) {
      dispatch({
        type: 'SELECT_HIGHLIGHTED_MARKER',
        selectedMarkerId: claimedHome.address,
      })
      dispatch({
        type: 'UPDATE_MAP_CENTER',
        center: {
          lat: claimedHome.lat,
          lng: claimedHome.lng,
        },
      })
      setTimeout(() => {
        dispatch({
          type: 'SELECT_HIGHLIGHTED_HOME',
          selectedAddress: claimedHome.address,
        })
      }, 1000)
    }
  }, [claimedHome])

  useEffect(() => {
    if (map.current && center) {
      map.current.jumpTo({
        center: [
          center.lng,
          center.lat,
        ],
      })
    }
  }, [map.current, center])

  useEffect(() => {
    mapboxgl.accessToken = document.querySelector('meta[name="mapbox-api-key"]').content

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      center: [center.lng, center.lat],
      zoom: 15,
      style: mapStyle,
    })

    map.current.on('load', loaded)
    map.current.on('click', onClicked)
    map.current.on('moveend', debounce(getAndSetBounds, 1000))

    // Attempt to get user location to center map.
    getUserLocation()
      .then((results) => {
        dispatch({
          type: 'UPDATE_MAP_CENTER',
          center: {
            lat: results.latitude,
            lng: results.longitude,
          },
        })
      })
      .catch((e) => {
        if (e.code && e.code !== 1) {
          dispatch({
            type: 'OPEN_MODAL',
            heading: 'Could not get location.',
            htmlBody: 'This may be due to device, browser or network settings.',
          })
        }
      })

    setTimeout(() => {
      getAndSetBounds()
      setRenderComponents(true)
    }, 500)

    return () => {
      map.current.off('load', loaded)
      map.current.off('click', onClicked)
      map.current.off('moveend', getAndSetBounds)
      map.current.remove()
    }
  }, [])

  const renderChildren = () => {
    if (renderComponents) {
      return (
        <>
          <SignupModalEntry mapboxgl={mapboxgl} map={map} />
          <MapSearchWrapper mapboxgl={mapboxgl} map={map} />
          <Markers mapboxgl={mapboxgl} map={map} />
          <StyleToggle map={map} />
          <UserLocation />
        </>
      )
    }

    return null
  }

  return (
    <>
      {renderChildren()}
      <div
        ref={mapContainer}
        className='mapContainer'
      />
    </>
  )
}

export default Map
