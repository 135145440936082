const INITIAL_STATE = {
  // Currently defaults to Birmingham..
  center: {
    lat: 33.5048937,
    lng: -86.8151457,
  },
  bounds: {
    ne: {
      lng: null,
      lat: null,
    },
    sw: {
      lng: null,
      lat: null,
    },
  },
  loading: false,
}

const mapBoundsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_MAP_CENTER':

      return {
        ...state,
        center: action.center,
      }

    case 'UPDATE_MAP_BOUNDS':

      return {
        ...state,
        bounds: action.bounds,
      }

    case 'SET_MAP_LOADING':

      return {
        ...state,
        loading: action.loading,
      }

    default: return state
  }
}

export default mapBoundsReducer
