import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import Filters from './Filters.jsx'
import HomeList from './sidebar/HomeList.jsx'

const selectProperties = createSelector(
  (state) => state.properties,
  (properties) => properties && properties.length > 0,
)

const SideBar = () => {
  const propertiesPresent = useSelector(selectProperties)

  return (
    <div id="map-sidebar-inner" className={propertiesPresent ? 'properties-present' : ''}>
      <Filters />
      <div id="scrollable-sidebar-area">
        <HomeList />
      </div>
    </div>
  )
}

export default SideBar
