import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

const selectCurrentUser = createSelector(
  (state) => state.currentUser,
  (currentUser) => currentUser,
)

const selectUserId = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.id,
)

const SignupButton = ({ claimButton, property }) => {
  const userId = useSelector(selectUserId)
  const renderMessage = () => {
    if (claimButton && property) {
      return (
        <a href={`/users/sign_up?address=${property.address}&city=${property.city}&state=${property.state}&zip=${property.zip}&lat=${property.lat}&lng=${property.lng}`}>
          If this is your home, Claim It Now!
        </a>
      )
    }

    return (
      <>
        Please
        {' '}
        <a href="/users/sign_up">Sign Up</a>
        {' '}
        or
        {' '}
        <a href="/users/sign_in">Log In</a>
        {' '}
        <span
          className="block lg:inline"
        >
          to make an offer or claim this home
        </span>
      </>
    )
  }

  if (!userId) {
    return (
      <div className="button-option-wrapper">
        <div className="quick-signup-link">
          {renderMessage()}
        </div>
      </div>
    )
  }

  return null
}

SignupButton.propTypes = {
  claimButton: PropTypes.bool,
  property: PropTypes.object,
}

SignupButton.defaultProps = {
  claimButton: false,
  property: null,
}

export default SignupButton
