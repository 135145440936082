import axios from 'axios'

function getHomeDetails() {
  const getHome = ({ id, callbackFunction }) => {
    axios.get(`/locations/${id}.json`)
      .then((response) => {
        callbackFunction(response)
      })
      .catch(() => {
      })
  }

  return [getHome]
}

export default getHomeDetails
