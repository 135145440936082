import React from 'react'
import PropTypes from 'prop-types'

const BedroomsCount = ({ count, rounded }) => (
  <div
    className={`home-card-details-item${rounded ? ' rounded' : ''}`}
  >
    <p>Bedrooms</p>
    <h4>{count || '---'}</h4>
  </div>
)

BedroomsCount.propTypes = {
  count: PropTypes.number,
  rounded: PropTypes.bool,
}

BedroomsCount.defaultProps = {
  count: null,
  rounded: false,
}

export default BedroomsCount
