import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from '../store'

export const setupApp = (component, wrapper) => {
  const Comp = component
  const domNode = document.getElementById(wrapper)

  if (Comp && domNode && !domNode.hasChildNodes()) {
    ReactDOM.render(
      <Provider store={store}>
        <Comp />
      </Provider>,
      domNode,
    )
  }
}

export const destroyApp = (wrapper) => {
  const domNode = document.getElementById(wrapper)

  if (domNode) {
    ReactDOM.unmountComponentAtNode(domNode)
  }
}
