import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import FilterImage from '../../../images/filter.svg'
import FilterOpenImage from '../../../images/filter-white.svg'
import CloseImage from '../../../images/close-white.svg'

const selectFilters = createSelector(
  (state) => state.filters,
  (filters) => filters,
)

const selectOpen = createSelector(
  selectFilters,
  (filters) => filters.open,
)

const FilterToggle = () => {
  const open = useSelector(selectOpen)
  const dispatch = useDispatch()

  const toggleFilterOptions = () => {
    dispatch({ type: 'TOGGLE_FILTER_OPTIONS' })
  }

  return (
    <div
      id="filter-list-toggle"
      className={`${open ? 'open' : ''}`}
      onClick={toggleFilterOptions}
    >
      <img src={FilterImage} />
      <img src={FilterOpenImage} className="filter-open-image" />
      <span>Filters</span>
      <div className="filter-close-image">
        <img src={CloseImage} />
      </div>
    </div>
  )
}

export default FilterToggle
