import axios from 'axios'
import { useDispatch } from 'react-redux'

function waveAtHome(property) {
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch({ type: 'CLOSE_MODAL' })
  }

  function wave() {
    dispatch({
      type: 'SET_MODAL_LOADING',
      loading: true,
    })

    axios.post('/waves.json', {
      location: {
        source: 'Mapbox',
        source_id: property.source_id,
        lat: property.lat,
        lng: property.lng,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        dispatch({
          type: 'CLOSE_HOME_HIGHLIGHT',
        })
        dispatch({
          type: 'ADD_WAVE_ID',
          id: property.source_id,
        })
        dispatch({
          type: 'OPEN_MODAL',
          heading: 'Wave to the Homeowner?',
          htmlBody: `<p><strong class='text-primary-green'>Thank you!</strong>, We've waved to the homeowner at ${property.address}. Please check back often to see if they've decided to Go Green!</p><br /><p class='text-center w-full'></p>`,
          buttonText: 'Search for Other Homes',
          buttonClass: 'block mx-auto mb-2 text-center text-primary-green',
          buttonFunction: closeModal,
        })
        dispatch({
          type: 'SET_MODAL_LOADING',
          loading: false,
        })
      })
      .catch((e) => {
        let responseMessage
        if (e.response.data.errors) {
          responseMessage = `<p>${e.response.data.errors[0]}</p>`
        } else {
          responseMessage = '<p>We were unable to wave at this home.</p>'
        }

        dispatch({
          type: 'OPEN_MODAL',
          heading: 'Wave to the Homeowner?',
          htmlBody: responseMessage,
        })
        dispatch({
          type: 'SET_MODAL_LOADING',
          loading: false,
        })
      })
  }

  return [wave]
}

export default waveAtHome
