import React, { useState } from 'react'
import PropTypes from 'prop-types'

const ClaimDisclaimer = ({ status }) => {
  const [messageClosed, setMessageClosed] = useState(false)

  const closeMessage = () => {
    setMessageClosed(true)
  }

  const renderCloseOption = () => (
    <div id="close-claim-disclaimer" onClick={closeMessage}>
      x
    </div>
  )

  if (status && !messageClosed && (status === 'accepting_offers')) {
    return (
      <div id="claim-disclaimer">
        {renderCloseOption()}
        By Going Green, you agree to receive offers on your
        home and OMG will immediately turn your home Green on the map.
        When interested buyers see that your home is Green,
        they will know you are open to receiving offers.
        Of course, you aren’t obligated to accept any offers!
        To help save you time, OMG can also automatically reject
        any offers that fall below your private Minimum Offer price,
        if you choose to enter one below.
      </div>
    )
  }

  return null
}

ClaimDisclaimer.propTypes = {
  status: PropTypes.string,
}

ClaimDisclaimer.defaultProps = {
  status: null,
}

export default ClaimDisclaimer
