const styleInput = (e) => {
  for (let { target } = e; target && target !== document; target = target.parentNode) {
    if (target.matches('input#offer_file')) {
      const file = target.value.split('\\')
      const filename = file[file.length - 1]
      const nameInput = document.querySelector('#offer-file-name')
      const fileIcon = '<div class="upload-file-icon"></div>'
      const checkIcon = '<div class="upload-check-icon"></div>'

      nameInput.innerHTML = `<div class="file-preview">${fileIcon}${filename}${checkIcon}</div>`
    }
  }
}

export const uploadFile = () => {
  document.addEventListener('change', styleInput)
}

export const uploadCleanup = () => {
  document.removeEventListener('change', styleInput)
}
