import React, { useEffect } from 'react'

const SiteCredits = () => {
  const changeFixedElementWidth = () => {
    const parentElement = document.getElementById('map-content-area')
    const fixedElement = document.getElementById('site-credits')

    if (parentElement && fixedElement) {
      let parentElementWidth
      if (window.innerWidth >= 768) {
        parentElementWidth = `${parentElement.getBoundingClientRect().width}px`
      } else {
        parentElementWidth = '100%'
      }
      fixedElement.style.width = parentElementWidth
    }
  }

  useEffect(() => {
    changeFixedElementWidth()
    window.addEventListener('resize', changeFixedElementWidth)

    return () => {
      window.removeEventListener('resize', changeFixedElementWidth)
    }
  }, [])

  return (
    <div id="site-credits">
      <span>&#169; OMG</span>
      <i className="fa fa-circle" aria-hidden="true" />
      <span>Patent Pending</span>
      <i className="fa fa-circle" aria-hidden="true" />
      <a href="/pages/terms-of-service">Terms</a>
      <i className="fa fa-circle" aria-hidden="true" />
      <a href="/pages/privacy-policy">Privacy</a>
      <i className="fa fa-circle" aria-hidden="true" />
      <a href="/pages/faq" title="Buying or selling a home FAQ sheet">FAQ</a>
      <i className="fa fa-circle" aria-hidden="true" />
      <a href="/pages/home-purchase-closing-checklist" title="Buying or selling a home checklist">Checklists</a>
    </div>
  )
}

export default SiteCredits
