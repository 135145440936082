import { useDispatch } from 'react-redux'
import getHomeDetails from './getHomeDetails'
import useReverseGeocode from './useReverseGeocode'

function selectHomeInSidebar() {
  const dispatch = useDispatch()
  const [getHome] = getHomeDetails()
  const [reverseGeocode] = useReverseGeocode()

  const onBackendCallSuccess = (e) => {
    dispatch({
      type: 'ADD_PROPERTIES',
      properties: [e.data],
    })
    dispatch({
      type: 'SELECT_HIGHLIGHTED_HOME',
      selectedAddress: e.data.address,
    })

    dispatch({
      type: 'SELECT_HIGHLIGHTED_MARKER',
      selectedMarkerId: e.data.address,
    })

    if (window.innerWidth >= 768) {
      dispatch({ type: 'OPEN_HOME_HIGHLIGHT' })
    }
  }

  const onGeocodeSuccess = (e, marker) => {
    const addressValues = e.data.features[0]
    const addressParts = addressValues.place_name.split(', ')

    const property = {
      id: marker.id,
      source_id: String(marker.id),
      address: addressParts[0],
      city: addressParts[1],
      state: addressParts[2].split(' ')[0],
      zip: addressParts[2].split(' ')[1],
      lat: addressValues.geometry.coordinates[1],
      lng: addressValues.geometry.coordinates[0],
    }

    dispatch({
      type: 'ADD_PROPERTIES',
      properties: [property],
    })
    dispatch({
      type: 'SELECT_HIGHLIGHTED_HOME',
      selectedAddress: addressParts[0],
    })
    dispatch({
      type: 'SELECT_HIGHLIGHTED_MARKER',
      selectedMarkerId: addressParts[0],
    })

    if (window.innerWidth >= 768) {
      dispatch({ type: 'OPEN_HOME_HIGHLIGHT' })
    }
  }

  function selectHome(marker, accessToken) {
    const { id, status, geometry } = marker
    const lat = geometry.coordinates[1]
    const lng = geometry.coordinates[0]

    if (status) {
      getHome({
        id,
        callbackFunction: onBackendCallSuccess,
      })
    } else {
      reverseGeocode({
        lat,
        lng,
        accessToken,
        callbackFunction: (e) => onGeocodeSuccess(e, marker),
      })
    }
  }

  return [selectHome]
}

export default selectHomeInSidebar
