const handleResize = () => {
  const { innerWidth } = window

  if (innerWidth >= 768) {
    document.getElementById('main-top-navigation').classList
      .remove('mobile-nav-open')
  }
}

const toggleProfileLinks = () => {
  const profileLinksWrapper = document.getElementById('account-sub-links')
  profileLinksWrapper.classList.toggle('sub-links-open')
}

export const navInit = () => {
  const navInstance = document.getElementById('nav-toggle')

  if (navInstance) {
    window.addEventListener('resize', handleResize)

    navInstance.onclick = () => {
      document.getElementById('nav-content').classList
        .toggle('hidden')
      document.getElementById('mobile-nav-backdrop').classList
        .toggle('hidden')
      document.getElementById('nav-content').classList
        .add('animate__animated', 'animate__slideInLeft')
      document.getElementById('nav-toggle-open').classList
        .toggle('hidden')
      document.getElementById('nav-toggle-closed').classList
        .toggle('hidden')
      document.getElementById('main-top-navigation').classList
        .toggle('mobile-nav-open')
    }
  }

  const profileLinksTrigger = document.getElementById('profile-links-trigger')
  if (profileLinksTrigger) {
    profileLinksTrigger.addEventListener('mouseover', toggleProfileLinks)
    profileLinksTrigger.addEventListener('mouseout', toggleProfileLinks)
  }
}

export const navBackdropHide = () => {
  document.getElementById('mobile-nav-backdrop')
    .classList
    .add('hidden')
}

export const navCleanup = () => {
  const navInstance = document.getElementById('nav-toggle')

  if (navInstance) {
    navInstance.onclick = null
    window.removeEventListener('resize', handleResize)
  }

  const profileLinksTrigger = document.getElementById('profile-links-trigger')

  if (profileLinksTrigger) {
    profileLinksTrigger.removeEventListener('mouseover', toggleProfileLinks)
    profileLinksTrigger.removeEventListener('mouseout', toggleProfileLinks)
  }
}
