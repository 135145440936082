import { combineReducers } from 'redux'
import currentUserReducer from './currentUserReducer'
import mapBoundsReducer from './mapBoundsReducer'
import markersReducer from './markersReducer'
import modalReducer from './modalReducer'
import propertiesReducer from './propertiesReducer'
import homeHighlightReducer from './homeHighlightReducer'
import filtersReducer from './filtersReducer'

const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  homeHighlight: homeHighlightReducer,
  mapBounds: mapBoundsReducer,
  markers: markersReducer,
  modal: modalReducer,
  properties: propertiesReducer,
  filters: filtersReducer,
})

export default rootReducer
