/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import Checkbox from '../forms/Checkbox.jsx'

const valuePairs = [
  ['House', 'house'],
  ['Manufactured', 'manufactured'],
  ['Condo', 'condo'],
  ['Multi Family', 'multi_family'],
  ['Apartment', 'apartment'],
  ['Land', 'land'],
  ['Townhome', 'townhome'],
  ['Other', 'other'],
]

const selectFilters = createSelector(
  (state) => state.filters,
  (filters) => filters,
)

const selectHomeTypes = createSelector(
  selectFilters,
  (filters) => filters.homeTypes,
)

const HomeTypeFilter = () => {
  const homeTypes = useSelector(selectHomeTypes)
  const [showAll, setShowAll] = useState({ checked: true })
  const dispatch = useDispatch()

  const handleFilter = (e) => {
    if (e.currentTarget.checked) {
      setShowAll({ checked: false })
    }
    dispatch({
      type: 'UPDATE_FILTER_OPTIONS',
      homeType: e.target.value,
      checked: e.currentTarget.checked,
    })
  }

  const setAll = (e) => {
    dispatch({ type: 'CLEAR_HOME_TYPE_OPTIONS' })
    setShowAll({ checked: e.currentTarget.checked })
  }

  return (
    <div className="checkbox-filters">
      <Checkbox
        name='All'
        value='all'
        checkedState={showAll}
        updateAction={setAll}
      />

      {valuePairs.map((pairs) => (
        <Checkbox
          key={pairs[1]}
          name={pairs[0]}
          value={pairs[1]}
          checkedState={find(homeTypes, { option: pairs[1] })}
          updateAction={handleFilter}
        />
      ))}
    </div>
  )
}

export default HomeTypeFilter
