/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-underscore-dangle */
import { createStore } from 'redux'
import rootReducer from '../reducers'

let store

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production') {
  store = createStore(rootReducer)
} else {
  store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  )
}

export default store
