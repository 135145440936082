/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { useDispatch } from 'react-redux'
import selectSearchedResult from '../../hooks/selectSearchedResult'

function SearchInput({
  map,
  mapboxgl,
  callbackFunction
}) {
  const searchContainer = useRef(null)
  const geoCoder = useRef(null)
  const [searchMounted, setSearchMounted] = useState(false)
  const [selectSearched] = selectSearchedResult()
  const dispatch = useDispatch()

  // Since we have limited space on mobile, we clear the card
  // unless explicity clicked.
  const closeCardIfMobile = () => {
    if (window.innerWidth < 768) {
      dispatch({ type: 'CLEAR_PROPERTIES' })
      dispatch({ type: 'CLOSE_HOME_HIGHLIGHT' })
    }
  }

  const onResultFound = (event) => {
    selectSearched({
      event,
      geoCoder
    })
    if (callbackFunction) {
      callbackFunction()
    }
  }

  useEffect(() => {
    geoCoder.current = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
      marker: false,
      flyTo: { duration: 1000, essential: true },
      placeholder: 'Street Address, City, State, Zip Code',
    })

    geoCoder.current.on('result', onResultFound)

    return () => {
      geoCoder.current.off('result', onResultFound)
      geoCoder.current = null
    }
  }, [mapboxgl])

  useEffect(() => {
    if (geoCoder.current && map.current && searchMounted === false) {
      searchContainer.current.appendChild(geoCoder.current.onAdd(map.current))

      setSearchMounted(true)
    }
  }, [geoCoder.current, map.current])

  return (
    <div
      ref={searchContainer}
      onClick={closeCardIfMobile}
    />
  )
}

SearchInput.propTypes = {
  map: PropTypes.object.isRequired,
  mapboxgl: PropTypes.object.isRequired,
  callbackFunction: PropTypes.func,
}

SearchInput.defaultProps = {
  callbackFunction: null
}

export default SearchInput
