import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import claimHome from '../hooks/claimHome'

const selectCurrentUser = createSelector(
  (state) => state.currentUser,
  (currentUser) => currentUser,
)

const selectClaimed = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.claimed,
)

const selectUserId = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.id,
)

const makePropertySelector = () => createSelector(
  (state) => state.properties,
  (_, id) => id,
  (properties, id) => find(properties, { id }),
)

const ClaimHomeButton = ({ id }) => {
  const selectProperty = useMemo(makePropertySelector, [])
  const property = useSelector((state) => selectProperty(state, id))
  const claimed = useSelector(selectClaimed)
  const userId = useSelector(selectUserId)
  const dispatch = useDispatch()
  const [claim] = claimHome(property)

  const openConfirmation = () => {
    dispatch({
      type: 'OPEN_MODAL',
      heading: 'Claim this Home as Yours?',
      htmlBody: '<p>Claiming this house as your own lets you edit the details, upload images of it and most importantly, recieve offers on it! Please keep in mind, you can only claim one home at a time right now, so please make sure this is your home before you claim it. Once you claim a home, edit it’s details in the My Home tab.</p>',
      buttonText: 'This is My Home.  I want to Claim It!',
      buttonClass: 'btn btn-block btn-primary',
      buttonFunction: claim,
    })
  }

  if (
    claimed
    && find(claimed, { id: property.id })
  ) {
    return (
      <a href="/listing" className="btn btn-primary btn-block" style={{ margin: '0.5rem' }}>
        Edit My Home
      </a>
    )
  }

  if (
    userId
    && claimed.length <= 0
    && property
    && (!property.status || property.status === 'unclaimed')
  ) {
    return (
      <div className="button-option-wrapper">
        <button
          className="btn btn-primary btn-block"
          onClick={openConfirmation}
        >
          This is My Home.  I want to Claim It!
        </button>
      </div>
    )
  }

  return null
}

ClaimHomeButton.propTypes = {
  id: PropTypes.number.isRequired,
}

export default ClaimHomeButton
