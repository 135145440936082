const INITIAL_STATE = {
  open: false,
  heading: null,
  htmlBody: null,
  componentPath: null,
  buttonText: null,
  buttonClass: null,
  buttonFunction: null,
  loading: false,
}

const modalReducer = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        open: true,
        heading: action.heading,
        htmlBody: action.htmlBody,
        componentPath: action.componentPath,
        buttonText: action.buttonText,
        buttonClass: action.buttonClass,
        buttonFunction: action.buttonFunction,
      }

    case 'CLOSE_MODAL':
      return INITIAL_STATE

    case 'SET_MODAL_LOADING':
      return {
        ...state,
        loading: action.loading,
      }

    default: return state
  }
}

export default modalReducer
