import React, { useState } from 'react'
import PropTypes from 'prop-types'

const FilterOptions = ({ children, title }) => {
  const [open, setOpen] = useState(true)

  const toggleFilterOptions = () => {
    setOpen(!open)
  }

  const renderOptions = () => {
    if (open) {
      return (
        <div className="filter-list-options-output">
          {children}
        </div>
      )
    }

    return null
  }

  return (
    <div className="filter-list-options">
      <div
        onClick={toggleFilterOptions}
        className="filter-list-option-title"
      >
        {title}
        <i
          className={`fa fa-angle-${open ? 'down' : 'up'}`}
          aria-hidden="true"
        />
      </div>
      {renderOptions()}
    </div>
  )
}

FilterOptions.propTypes = {
  children: PropTypes.object.isRequired,
  title: PropTypes.string,
}

export default FilterOptions
