/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import store from './store'

export const attachUserSession = () => {
  const token = document.querySelector('[name=csrf-token]').content
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token

  axios.get('/current_user.json')
    .then((response) => {
      if (response.data) {
        store.dispatch({
          type: 'ADD_USER_SESSION',
          user: response.data,
        })
      }
    })
    .catch(() => {
    })
}
