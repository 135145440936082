import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import Marker from './Marker.jsx'
import useListFilter from '../../hooks/useListFilter'

const selectMarkers = createSelector(
  (state) => state.markers,
  (markers) => markers,
)

const selectFilters = createSelector(
  (state) => state.filters,
  (filters) => filters,
)

const selectBounds = createSelector(
  (state) => state.mapBounds,
  (mapbounds) => mapbounds.bounds,
)

function Markers({
  map,
  mapboxgl,
}) {
  const bounds = useSelector(selectBounds)
  const markers = useSelector(selectMarkers)
  const filters = useSelector(selectFilters)
  const [updateList] = useListFilter()
  const dispatch = useDispatch()

  const onUpdateListSuccess = (response) => {
    if (find(filters.status, { option: 'unclaimed', checked: false })) {
      dispatch({ type: 'ADD_OMG_MARKERS_TO_MARKERS', markers: response.data })
    }

    dispatch({
      type: 'SET_CARD_LOADING',
      loading: false,
    })
  }

  useEffect(() => dispatch({
    type: 'REMOVE_ALL_MARKERS',
  }), [])

  useEffect(() => {
    if (bounds && bounds.sw) {
      updateList({
        view: 'map',
        callbackFunction: onUpdateListSuccess,
      })
    }
  }, [bounds.sw.lat, bounds.sw.lat])

  if (map && markers) {
    return (
      <>
        {markers.map((marker) => (
          <Marker id={marker.id} map={map} mapboxgl={mapboxgl} key={marker.id} />
        ))}
      </>
    )
  }

  return null
}

Markers.propTypes = {
  map: PropTypes.object,
  mapboxgl: PropTypes.object.isRequired,
  bounds: PropTypes.object,
}

Markers.defaultProps = {
  map: null,
  bounds: null,
}

export default Markers
