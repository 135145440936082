import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import PropTypes from 'prop-types'
import find from 'lodash/find'

const selectCurrentUser = createSelector(
  (state) => state.currentUser,
  (currentUser) => currentUser,
)

const selectCurrentUserId = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.id,
)

const selectClaimed = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.claimed,
)

const makePropertySelector = () => createSelector(
  (state) => state.properties,
  (_, id) => id,
  (properties, id) => find(properties, { id }),
)

const MakeOfferButton = ({ id }) => {
  const currentUserId = useSelector(selectCurrentUserId)
  const claimed = useSelector(selectClaimed)
  const selectProperty = useMemo(makePropertySelector, [])
  const property = useSelector((state) => selectProperty(state, id))

  if (
    currentUserId
    && !find(claimed, { id })
    && property
    && property.listing_id
    && property.status === 'accepting_offers'
  ) {
    return (
      <div id="make-offer-to-home-wrapper">
        <a
          onClick={window.showModal}
          href={`/listings/${property.listing_id}/offers/new`}
          className="btn btn-primary btn-block"
        >
          Make an Offer on this Home
        </a>
      </div>
    )
  }

  return null
}

MakeOfferButton.propTypes = {
  id: PropTypes.number.isRequired,
}

export default MakeOfferButton
