/* eslint-disable radix */
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getHomeDetails from './getHomeDetails'

function claimHome(property) {
  const dispatch = useDispatch()
  const [getHome] = getHomeDetails()

  const onGetHomeSuccess = (e) => {
    dispatch({ type: 'ADD_OMG_MARKERS_TO_MARKERS', markers: [e.data] })
  }

  function claim() {
    dispatch({
      type: 'SET_MODAL_LOADING',
      loading: true,
    })

    axios.post('/listing.json', {
      listing: {
        source: 'Mapbox',
        source_id: property.source_id,
        lat: property.lat,
        lng: property.lng,
      },
    })
      .then((response) => {
        if (response.data.id) {
          dispatch({
            type: 'REMOVE_MARKER_FROM_MARKERS',
            id: parseInt(property.source_id),
          })
          dispatch({
            type: 'REMOVE_PROPERTY',
            id: property.id,
          })
          dispatch({
            type: 'ADD_CLAIMED_HOME',
            id: response.data.location_id,
            lat: property.lat,
            lng: property.lng,
          })
          dispatch({
            type: 'CLOSE_HOME_HIGHLIGHT',
          })
          getHome({
            id: response.data.location_id,
            callbackFunction: onGetHomeSuccess,
          })
          dispatch({
            type: 'CLOSE_MODAL',
          })
          // eslint-disable-next-line no-undef
          Turbolinks.visit('/listing')
        } else {
          dispatch({
            type: 'OPEN_MODAL',
            heading: 'Claim this Home as Yours?',
            htmlBody: '<p>There was an issue claiming this location.</p>',
          })
        }

        dispatch({
          type: 'SET_MODAL_LOADING',
          loading: false,
        })
      })
      .catch(() => {
        dispatch({
          type: 'OPEN_MODAL',
          heading: 'Claim this Home as Yours?',
          htmlBody: '<p>There was an issue claiming this location.</p>',
        })
        dispatch({
          type: 'SET_MODAL_LOADING',
          loading: false,
        })
      })
  }

  return [claim]
}

export default claimHome
