import React from 'react'
import PropTypes from 'prop-types'

const BathroomsCount = ({ count, rounded }) => (
  <div
    className={`home-card-details-item${rounded ? ' rounded' : ''}`}
  >
    <p>Bathrooms</p>
    <h4>{count || '---'}</h4>
  </div>
)

BathroomsCount.propTypes = {
  count: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  rounded: PropTypes.bool,
}

BathroomsCount.defaultProps = {
  rounded: false,
  count: null,
}

export default BathroomsCount
