const slideCardIntoView = (id) => {
  const card = document.getElementById(`home-card-${id}`)

  if (card) {
    card.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    })
  }
}

export default slideCardIntoView
