const toggleHidden = (e) => {
  /* eslint-disable no-loop-func */
  for (let { target } = e; target && target !== document; target = target.parentNode) {
    if (target.matches('[data-toggle]')) {
      e.preventDefault()
      e.stopPropagation()

      const toggleNames = target.dataset.toggle.split(',')

      toggleNames.forEach((toggleName) => {
        const element = document.querySelector(toggleName)

        element.classList.toggle('hidden')
      })

      break
    }
  }
  /* eslint-enable no-loop-func */
}

export const initToggleHidden = () => {
  document.addEventListener('click', toggleHidden)
}

export const cleanupToggleHidden = () => {
  document.removeEventListener('click', toggleHidden)
}
