const initialize = (event) => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML('beforebegin', `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename">${file.name}</span>
    </div>
  `)
}

const onStart = (event) => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove('direct-upload--pending')
}

const onProgress = (event) => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
}

const onError = (event) => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add('direct-upload--error')
  element.setAttribute('title', error)
}

const onEnd = (event) => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add('direct-upload--complete')
}

export const setupDirectUpload = () => {
  window.addEventListener('direct-upload:initialize', initialize)
  window.addEventListener('direct-upload:start', onStart)
  window.addEventListener('direct-upload:progress', onProgress)
  window.addEventListener('direct-upload:error', onError)
  window.addEventListener('direct-upload:end', onEnd)
}

export const teardownDirectUpload = () => {
  window.removeEventListener('direct-upload:initialize', initialize)
  window.removeEventListener('direct-upload:start', onStart)
  window.removeEventListener('direct-upload:progress', onProgress)
  window.removeEventListener('direct-upload:error', onError)
  window.removeEventListener('direct-upload:end', onEnd)
}
