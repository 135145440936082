/* eslint-disable no-case-declarations */
import remove from 'lodash/remove'

const INITIAL_STATE = []

const propertiesReducer = (state = INITIAL_STATE, action) => {
  const newState = Array.from(state)

  switch (action.type) {
    case 'ADD_PROPERTIES':
      return newState.map(
        (s) => action.properties.find(
          (t) => t.address === s.address,
        ) || s,
      ).concat(
        action.properties.filter(
          (s) => !newState.find((t) => t.address === s.address),
        ),
      )

    case 'REMOVE_PROPERTY':
      remove(newState, (property) => property.id === action.id)

      return newState

    case 'CLEAR_PROPERTIES':
      return INITIAL_STATE

    default: return state
  }
}

export default propertiesReducer
