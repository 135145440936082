import slideCardIntoView from '../utils/cards'

const INITIAL_STATE = {
  selectedAddress: null,
  selectedMarkerId: null,
  open: false,
  galleryOpen: false,
  cardLoading: false,
}

const homeHighlightReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'OPEN_HOME_HIGHLIGHT':
      return {
        ...state,
        open: true,
      }

    case 'CLOSE_HOME_HIGHLIGHT':
      return {
        ...state,
        open: false,
        galleryOpen: false,
      }

    case 'SELECT_HIGHLIGHTED_HOME':
      slideCardIntoView(action.selectedAddress)

      return {
        ...state,
        selectedAddress: action.selectedAddress,
      }

    case 'SELECT_HIGHLIGHTED_MARKER':
      return {
        ...state,
        selectedMarkerId: action.selectedMarkerId,
      }

    case 'SHOW_HOMEHIGHLIGHT_GALLERY':
      return {
        ...state,
        galleryOpen: true,
      }

    case 'HIDE_HOMEHIGHLIGHT_GALLERY':
      return {
        ...state,
        galleryOpen: false,
      }

    case 'SET_CARD_LOADING':
      return {
        ...state,
        cardLoading: action.loading,
      }

    default: return state
  }
}

export default homeHighlightReducer
