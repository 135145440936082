const amenityOptions = [
  'A/C',
  'Pool/Spa',
  'Waterfront',
  'City Views',
  'Park Views',
  'Mountain Views',
  'Water Views',
  'Single Story',
  'Has Basement',
  'Has Garage',
]

export default amenityOptions
