import React from 'react'
import PropTypes from 'prop-types'

const YearBuilt = ({ year, rounded }) => (
  <div
    className={`home-card-details-item${rounded ? ' rounded' : ''}`}
  >
    <p>Year Built</p>
    <h4>{year || '---'}</h4>
  </div>
)

YearBuilt.propTypes = {
  year: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  rounded: PropTypes.bool,
}

YearBuilt.defaultProps = {
  rounded: false,
  year: null,
}

export default YearBuilt
