import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../../../images/logo.png'

const SlideOne = ({ handleChangeIndex }) => {
  return (
    <div className="signup-popup-slide-area">
      <div id="signup-popup-logo-wrapper">
        <img src={Logo} />
      </div>
      <h1>
        OMG is Transforming the way Real Estate Works!
      </h1>
        <p>In less than 1-minute you can begin receiving offers directly on Your Home or search for Off Market homes on our Private Network.  OMG is 100% Free for anyone to use.  There are No Costs and No Obligations!</p>
        
        <p>Here’s why OMG was created:</p>
        <ul>
        <li><u>Homeowners:</u> Have you ever wondered what someone searching for a home in your neighborhood might offer on Your Home?  Even if you aren’t anxious to sell today?  With OMG, spend 1-minute claiming your home and you can finally find out!</li>
        <li><u>House Hunters & Real Estate Agents:</u> It’s frustrating to know that only 1% of homes are “For Sale” at any given time.  At OMG, we believe everything is for sale at a certain price.  Simply spend 1-minute creating an account and gain access to a portion of the other 99% of Off-Market homes!</li>
        </ul>
        <p>Get started in 3 Easy Steps!</p>

      <div
        className="btn btn-block btn-primary mt-6"
        onClick={() => handleChangeIndex(1)}
      >
        Next
      </div>
    </div>
  )
}

SlideOne.propTypes = {
  handleChangeIndex: PropTypes.func.isRequired,
}

export default SlideOne
