import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import Home from './Home.jsx'
import useListFilter from '../../hooks/useListFilter'
import LogoImage from '../../../images/logo.png'

const selectBounds = createSelector(
  (state) => state.mapBounds,
  (mapBounds) => mapBounds.bounds,
)

const selectProperties = createSelector(
  (state) => state.properties,
  (properties) => properties,
)

const selectSelectedAddress = createSelector(
  (state) => state.homeHighlight,
  (homeHighlight) => homeHighlight.selectedAddress,
)

const HomeList = () => {
  const bounds = useSelector(selectBounds)
  const properties = useSelector(selectProperties)
  const selectedAddress = useSelector(selectSelectedAddress)
  const dispatch = useDispatch()
  const [updateList] = useListFilter()
  const windowWidth = window.innerWidth

  const onUpdateSuccess = (response) => {
    dispatch({
      type: 'ADD_PROPERTIES',
      properties: response.data,
    })
  }

  useEffect(() => dispatch({
    type: 'CLEAR_PROPERTIES',
  }), [])

  // Note: This get's called when the bounds change
  //  from drag but also on flyto end.
  useEffect(() => {
    if (bounds && bounds.sw && bounds.sw.lat) {
      updateList({
        callbackFunction: onUpdateSuccess,
      })
    }
  }, [bounds.sw.lat, bounds.sw.lat])

  const renderProperties = () => {
    if (properties && properties.length > 0) {
      return properties.map((property) => {
        if (windowWidth >= 768 || property.address === selectedAddress) {
          return (
            <Home key={property.id} propertyId={property.id} />
          )
        }

        return null
      })
    }

    return (
      <div id="no-properties-located-message">
        <img src={LogoImage} />

        <h2>Welcome to OMG, where Every Home is For Sale</h2>
        <h3>Click on the map or search to find properties.</h3>
        <p>Find homes by zip code, area name, address, street name, city or state.</p>
      </div>
    )
  }

  return (
    <>
      {renderProperties()}
    </>
  )
}

export default HomeList
