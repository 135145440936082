import React from 'react'
import { useDispatch } from 'react-redux'
import useUserLocation from '../../hooks/useUserLocation'

const UserLocation = () => {
  const [getUserLocation] = useUserLocation()
  const dispatch = useDispatch()

  const updateMapBasedOnLocation = () => {
    dispatch({
      type: 'OPEN_MODAL',
      heading: '',
      htmlBody: 'Requesting Permission to Locate..',
    })
    getUserLocation()
      .then((results) => {
        dispatch({
          type: 'CLOSE_MODAL',
        })
        dispatch({
          type: 'UPDATE_MAP_CENTER',
          center: {
            lat: results.latitude,
            lng: results.longitude,
          },
        })
      })
      .catch(() => {
        dispatch({
          type: 'CLOSE_MODAL',
        })
        dispatch({
          type: 'OPEN_MODAL',
          heading: 'Could not get location.',
          htmlBody: 'This may be due to device, browser or network settings.',
        })
      })
  }

  return (
    <div
      id="center-map-on-user-toggle"
      onClick={updateMapBasedOnLocation}
    >
      <i
        className="fa fa-location-arrow"
        aria-hidden="true"
      />
    </div>
  )
}

export default UserLocation
