/* eslint-disable radix */
import React, {
  useEffect, useRef, useMemo, useLayoutEffect,
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import selectHomeInSidebar from '../../hooks/selectHomeInSidebar'

const selectSelectedAddress = createSelector(
  (state) => state.homeHighlight,
  (homeHighlight) => homeHighlight.selectedMarkerId,
)

const selectCardLoading = createSelector(
  (state) => state.homeHighlight,
  (homeHighlight) => homeHighlight.cardLoading,
)

const makeMarkerSelector = () => createSelector(
  (state) => state.markers,
  (_, id) => id,
  (markers, id) => find(markers, { id }),
)

// eslint-disable-next-line react/display-name
const Marker = React.memo(({
  id,
  map,
  mapboxgl,
}) => {
  const markerRef = useRef(null)
  const selectMarker = useMemo(makeMarkerSelector, [])
  const marker = useSelector((state) => selectMarker(state, id))
  const selectedMarkerId = useSelector(selectSelectedAddress)
  const cardLoading = useSelector(selectCardLoading)
  const cardLoadingRef = useRef(cardLoading)
  const [selectHome] = selectHomeInSidebar()
  const markerId = `marker-${id}`
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    cardLoadingRef.current = cardLoading
  }, [cardLoading])

  const handleClick = (e) => {
    e.stopPropagation()
    if (!cardLoadingRef.current) {
      selectHome(marker, mapboxgl.accessToken)
    }
  }

  useEffect(() => {
    const el = document.createElement('div')
    el.className = `marker ${marker.status || ''} ${selectedMarkerId === marker.address || selectedMarkerId === parseInt(marker.source_id) ? 'currently-selected ' : ''}`
    el.id = markerId

    markerRef.current = new mapboxgl.Marker(el, {
      anchor: 'center',
    })
      .setLngLat(marker.geometry.coordinates)
      .addTo(map.current)

    markerRef.current.getElement().addEventListener('click', handleClick)

    return () => {
      markerRef.current.getElement().removeEventListener('click', handleClick)
      markerRef.current.remove()
      el.remove()
    }
  }, [])

  useEffect(() => {
    const markerElem = document.getElementById(markerId)

    if (
      selectedMarkerId
      && (
        selectedMarkerId === marker.address
        || selectedMarkerId === parseInt(marker.source_id)
      )
    ) {
      markerElem.classList.add('selected-marker')
    } else {
      markerElem.classList.remove('selected-marker')
    }
  }, [selectedMarkerId])

  return null
})

Marker.propTypes = {
  id: PropTypes.number.isRequired,
  map: PropTypes.object.isRequired,
  mapboxgl: PropTypes.object.isRequired,
}

export default Marker
