import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import find from 'lodash/find'
import HomeLabelImage from '../../../images/home-icon-white.svg'

const makePropertySelector = () => createSelector(
  (state) => state.properties,
  (_, propertyId) => propertyId,
  (properties, propertyId) => find(properties, { id: propertyId }),
)

const HomePopoutLabel = ({ propertyId }) => {
  const selectProperty = useMemo(makePropertySelector, [])
  const property = useSelector((state) => selectProperty(state, propertyId))

  const renderImage = () => (
    <img src={HomeLabelImage} className="home-label-image" />
  )

  if (!property.status) {
    return (
      <div className="home-popout-label inactive">
        {renderImage()}
        Nobody has claimed this home
      </div>
    )
  }

  if (property.status === 'pending_sale') {
    return (
      <div className="home-popout-label contract">
        {renderImage()}
        Sale Pending
      </div>
    )
  }

  if (property.status === 'claimed') {
    return (
      <div className="home-popout-label claimed">
        {renderImage()}
        This Home Not Currently Accepting Offers
      </div>
    )
  }

  return (
    <div className="home-popout-label">
      {renderImage()}
      This Home is Open to Offers
    </div>
  )
}

HomePopoutLabel.propTypes = {
  propertyId: PropTypes.number.isRequired,
}

export default HomePopoutLabel
