/* eslint-disable new-cap */
/* eslint-disable global-require */
document.addEventListener('turbolinks:load', () => {
  const passwordInput = document.querySelector('input.password')
  const hintElements = document.querySelectorAll('.password-hint')

  if (passwordInput && hintElements) {
    const passwordValidator = require('password-validator')
    const schema = new passwordValidator()
    const markValid = (el) => {
      el.classList.add('valid')
      el.classList.remove('invalid')
    }
    const markInvalid = (el) => {
      el.classList.add('invalid')
      el.classList.remove('valid')
    }

    schema
      .is().min(8)
      .has().uppercase()
      .has()
      .lowercase()
      .has()
      .symbols()

    passwordInput.addEventListener('input', (e) => {
      const invalid = schema.validate(e.target.value, { list: true })

      hintElements.forEach((hintElement) => {
        if (invalid.includes(hintElement.dataset.rule)) {
          markInvalid(hintElement)
        } else {
          markValid(hintElement)
        }
      })
    })
  }
})
