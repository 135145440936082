import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({
  name,
  checkedState,
  value,
  updateAction,
}) => (
    <span className="checkbox">
      <input
        className="check_boxes optional"
        type="checkbox"
        value={value}
        name={`filters[${value}]`}
        id={`filters_${value}`}
        onChange={updateAction}
        checked={checkedState ? checkedState.checked : false}
      />
      <label
        className="collection_check_boxes"
        htmlFor={`filters_${value}`}
      >
        {name}
      </label>
    </span>
)

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checkedState: PropTypes.object,
  value: PropTypes.string.isRequired,
  updateAction: PropTypes.func.isRequired,
}

Checkbox.defaultProps = {
  checkedState: {
    checked: false,
  },
}

export default Checkbox
